import { deleteDoc, doc } from 'firebase/firestore'
import React from 'react'
import { db } from '../../../firebase.config'
import { useCollection } from '../../../hooks/useCollection'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Container } from '../../../styles/Common.styled'
import { Table } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'
import SelectedFeatureDetails from './SelectedFeatureDetails'

export default function AdminAllBlogs() {
    const { document: blogs } = useCollection("Blogs", undefined, ["createdAt", "desc"])

    const handleDelete = async(id) => {
        await deleteDoc(doc(db, "Blogs", id));
    }
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Blogs</h2>
                    <Table grid="repeat(2, 1fr)" style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                        <div className='row'>
                            <div style={{ fontWeight: "700" }}>Title</div>
                            <div style={{ fontWeight: "700" }}>Action</div>
                        </div>
                        {
                            blogs?.map(blog => (
                                <div className="row">
                                    <p >{blog?.title}</p>
                                    {/* <button onClick={() => handleDelete(blog?.id)}>Edit</button> */}
                                    <button onClick={() => handleDelete(blog?.id)}>Delete</button>
                                </div>
                            ))
                        }
                    </Table>
                </Container>
            </UserDashboard>
        </ProtectedRoute>
    )
}
