import {  LinkedinShareButton, LinkedinIcon } from 'react-share';
const Linkedin = ({ url, quotes, hashtag }) => {
    return (
        <>

            <LinkedinShareButton
                url={url}
                quotes={quotes} 
            >
                <LinkedinIcon  style={{width: "40px", height: "40px"}}/>
            </LinkedinShareButton>

        </>
    )
}

export default Linkedin;