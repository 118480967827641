import React, { useState } from 'react'
import { useEffect } from 'react';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useCollection } from '../../../hooks/useCollection';
import { UserData, WaitingCard } from '../../../styles/UserAdmin.styled'
import ProjectDetails from './ProjectDetails'
import UserRightSide from './UserRightSide'

export default function UserContents() {
  const { document: customers } = useCollection("customers");
  const { user } = useAuthContext();

  let [customerObject, setCustomerObject] = useState([])


  useEffect(() => {
    const filteredcustomerObject = customers.filter(customer => {
      return customer?.userDetails?.email == user?.email
    });
    setCustomerObject(filteredcustomerObject);
  }, [customers, user])


  let allfeature = customerObject[0]?.features;
  let featuredCompleted = customerObject[0]?.features?.filter(feature => feature?.isDevelopmentDone)
  let featuredUnCompleted = customerObject[0]?.features?.filter(feature => !feature?.isDevelopmentDone);

  // Feature Progress tracking
  let featureProgressDays = customerObject[0]?.productDetails?.days * .80;

  let perFeaturePercentage = 75 / allfeature?.length
  let perFeaturePercentageIndividual = 100 / allfeature?.length

  let featureCompletedPercentage = Math.round(featuredCompleted?.length * perFeaturePercentage);
  let featureCompletedPercentageIndividual = Math.round(featuredCompleted?.length * perFeaturePercentageIndividual);
  return (
    <UserData>
      <ProjectDetails customerObject={customerObject} featureCompletedPercentage={featureCompletedPercentageIndividual} />
      <UserRightSide customerObject={customerObject[0]} featureCompletedPercentage={featureCompletedPercentage} />

    </UserData>
  )
}
