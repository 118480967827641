import React from 'react'
import { RightSideSection } from '../../../styles/UserAdmin.styled'
import CalenderBook from './CalenderBook'
import Progress from './Progress'

export default function UserRightSide({ customerObject, featureCompletedPercentage }) {
  const deliveryTime = customerObject?.productDetails?.days;
  let today = new Date().toISOString().slice(0, 10);

  const dayPassed = (new Date(today) - new Date(customerObject?.workOrderDate)) / (1000 * 60 * 60 * 24);

  return (
    <RightSideSection>
      <Progress customerObject={customerObject} dayPassed={dayPassed}  deliveryTime={deliveryTime} featureCompletedPercentage={featureCompletedPercentage} />
      <CalenderBook />
    </RightSideSection>
  )
}
