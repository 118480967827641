import React from 'react'
import { Container, Heading } from '../../../../styles/Common.styled'
import { GridFour, GridThree, GridTwo } from '../../../../styles/Grid.styled'
import { ConsultancySection } from '../../../../styles/Home.styled'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import dotImg from "../../../../assets/images/dots-map.svg"

export default function Consultancy() {
    return (
        <ConsultancySection>
            <Container>
                <GridTwo gap="0rem">
                    <div className='greet'>
                        <h2>Tech Solutions and Consultancy</h2>
                        <div className='grid_four'>
                            <div className='card'>
                                <h2><CountUp end={4} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+</h2>
                                <p>Years</p>
                            </div>
                            <div className='card'>
                                <h2><CountUp end={20} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+</h2>
                                <p>Projects</p>
                            </div>
                            <div className='card'>
                                <h2><CountUp end={8} redraw={true}>
                                    {({ countUpRef, start }) => (
                                        <VisibilitySensor onChange={start} delayedCall>
                                            <span ref={countUpRef} />
                                        </VisibilitySensor>
                                    )}
                                </CountUp>+</h2>
                                <p>Years of Expertise</p>
                            </div>
                        </div>
                        {/* <p>We take on difficult challenges and execute the most innovative digital solutions. We construct our projects with industry-leading technologies and provide our clients with the utmost commitment and care. We are goal oriented to help every business perfectly blend into the world of tech by providing consultancies on different levels of tech-related issues.</p> */}
                    </div>
                    <div>
                        <img src={dotImg} alt="dotImg" />
                    </div>

                </GridTwo>
            </Container>
        </ConsultancySection>
    )
}
