import React from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonContainer, Container } from '../styles/Common.styled'
import { Navigation, NavigationContent } from '../styles/Navbar.styled'
// import logo from "../assets/images/logo.svg"
import logo from "../assets/images/OngshakLogo1.png"
import rightArrow from "../assets/images/rightArrow.png"
import { useAuthContext } from '../hooks/useAuthContext'

import { FaAngleRight } from "react-icons/fa";


export default function Navbar({ height }) {
    const { user } = useAuthContext();
    return (
        <Navigation style={height > 360 ? { position: "sticky", backgroundColor: "#fff", boxShadow: "2px 2px 10px rgba(0,0,0,.2)", transition: ".1s all ease-in-out" } : { backgroundColor: "transparent" }}>
            <NavigationContent>
                <Link to="/">
                    <img src={logo} alt="logo" />
                </Link>
                {
                    user?.email ? <Link to="/user-admin">
                        <ButtonContainer width="200px" rsWidth="150px" bg="#fff" iconBg="#000" color="#000">
                            <div className='icons'>
                                <FaAngleRight />
                                {/* <img src={rightArrow} alt="right arrow" /> */}
                            </div>

                            <p>Dashboard</p>
                        </ButtonContainer>
                    </Link> : <Link to="/login">
                        <ButtonContainer rsWidth="150px" color="#000"  bg="#fff" iconBg="#000">
                            <div className='icons'>
                                <FaAngleRight />
                                {/* <img src={rightArrow} alt="right arrow" /> */}
                            </div>

                            <p>Login</p>
                        </ButtonContainer>
                    </Link>
                }

            </NavigationContent>
        </Navigation >
    )
}
