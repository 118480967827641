import React, { useState } from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Button, Container } from '../../../styles/Common.styled'
import { ServiceFormContainer } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function AdminCreateOrder() {
    let [error, setError] = useState("");
    // const [createOrderObj, setCreateOrderObj] = useState({
    //     sl_no: "",
    //     qoutation_no: "",
    //     order_id: "",
    //     order_date: "",
    //     project_name: "",
    //     project_type: "",
    //     company_name: "",
    //     development_timeline: "",
    //     start_date: "",
    //     end_date: "",
    //     total_project_value: ""
    // })
    const [createOrderObj, setCreateOrderObj] = useState({
        sl_no: "",
        qoutation_no: "",
        order_id: "",
        order_date: "",
        project_name: "",
        project_type: "",
        company_name: "",
        development_timeline: "",
        start_date: "",
        end_date: "",
        total_project_value: "",
        cashInFlow: {},
        cashOutFlow: []
    })

    const { addDocument, response } = useFirestore("create_order")

    const handleCreateOrder = (e) => {
        e.preventDefault();

        if (createOrderObj?.sl_no !== "" && createOrderObj?.qoutation_no !== "" && createOrderObj?.order_id !== "" && createOrderObj?.order_date !== "" && createOrderObj?.project_name !== "" && createOrderObj?.project_type !== "" && createOrderObj?.company_name !== "" && createOrderObj?.development_timeline !== "" && createOrderObj?.start_date !== "" && createOrderObj?.end_date !== "") {
            addDocument(createOrderObj)
        } else {
            setError("Please fill all the required field!");
            return
        }

        setCreateOrderObj({
            sl_no: "",
            qoutation_no: "",
            order_id: "",
            order_date: "",
            project_name: "",
            project_type: "",
            company_name: "",
            development_timeline: "",
            start_date: "",
            end_date: "",
            total_project_value: "",
            cashInFlow: {},
            cashOutFlow: []
        })
    }

    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <ServiceFormContainer style={{ margin: "2rem auto" }}>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>Create Order</h2>

                    <form onSubmit={handleCreateOrder}>
                        <label>
                            <p>Serial No:*</p>
                            <input type="number" placeholder='Serial No' value={createOrderObj.sl_no} onChange={e => setCreateOrderObj({ ...createOrderObj, sl_no: e.target.value })} />
                        </label>
                        <label>
                            <p>Qoutation No:*</p>
                            <input type="number" placeholder='Qoutation No' value={createOrderObj.qoutation_no} onChange={e => setCreateOrderObj({ ...createOrderObj, qoutation_no: e.target.value })} />
                        </label>
                        <label>
                            <p>Order Id:*</p>
                            <input type="text" placeholder='Order Id' value={createOrderObj.order_id} onChange={e => setCreateOrderObj({ ...createOrderObj, order_id: e.target.value })} />
                        </label>
                        <label>
                            <p>Order date:*</p>
                            <input type="date" placeholder='Order Date' value={createOrderObj.order_date} onChange={e => setCreateOrderObj({ ...createOrderObj, order_date: e.target.value })} />
                        </label>
                        <label>
                            <p>Project Name:*</p>
                            <input type="text" placeholder='Project Name' value={createOrderObj.project_name} onChange={e => setCreateOrderObj({ ...createOrderObj, project_name: e.target.value })} />
                        </label>
                        <label>
                            <p>Project Type:*</p>
                            <input type="text" placeholder='Project Type' value={createOrderObj.project_type} onChange={e => setCreateOrderObj({ ...createOrderObj, project_type: e.target.value })} />
                        </label>
                        <label>
                            <p>Company Name:*</p>
                            <input type="text" placeholder='Company Name' value={createOrderObj.company_name} onChange={e => setCreateOrderObj({ ...createOrderObj, company_name: e.target.value })} />
                        </label>
                        <label>
                            <p>Development Timeline:*</p>
                            <input type="text" placeholder='Development Timeline' value={createOrderObj.development_timeline} onChange={e => setCreateOrderObj({ ...createOrderObj, development_timeline: e.target.value })} />
                        </label>
                        <label>
                            <p>Start Date:*</p>
                            <input type="date" placeholder='Start Date' value={createOrderObj.start_date} onChange={e => setCreateOrderObj({ ...createOrderObj, start_date: e.target.value })} />
                        </label>
                        <label>
                            <p>End Date:*</p>
                            <input type="date" placeholder='End Date' value={createOrderObj.end_date} onChange={e => setCreateOrderObj({ ...createOrderObj, end_date: e.target.value })} />
                        </label>
                        <label>
                            <p>Total Project Value:*</p>
                            <input type="number" placeholder='Total Project Value' value={createOrderObj.total_project_value} onChange={e => setCreateOrderObj({ ...createOrderObj, total_project_value: e.target.value })} />
                        </label>
                        <input type="submit" value="add" />
                    </form>
                    {
                        error && <p style={{ textAlign: "center", color: "red", fontSize: "2rem" }}>{error}</p>
                    }
                    {/* <Button >Submit</Button> */}
                </ServiceFormContainer >
            </UserDashboard >
        </ProtectedRoute>
    )
}
