import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Banner from '../../Shared/Banner'
import Footer from '../../Shared/Footer'
import Navbar from '../../Shared/Navbar'
import Employee from '../AboutUs/Components/Employee'
import team from "../../assets/images/team.webp"

export default function Teams() {
  
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, [])

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;
    setHeight(winScroll);
  };
  return (
    <div>
      <Navbar height={height} />
      <Banner bg={team}>
        <h2>Teams</h2>
      </Banner>
      <Employee />
      <Footer />
    </div>
  )
}
