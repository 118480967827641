import React from "react";
import {
  PricingList,
  StartedAskingSection,
  StepCard,
} from "../../../styles/GetStarted.styled";
import { GridThree } from "../../../styles/Grid.styled";
import { FaArrowAltCircleRight, FaCheckCircle } from "react-icons/fa";
import leaf from "../../../assets/images/leaf.png";

export default function StartedFeaturePricingList({
  setSelected,
  selected,
  services,
}) {
  let filteredFeature = services?.filter(
    (service) => service?.service?.service_name === selected?.business
  );
  let filteredPricingList = filteredFeature[0]?.allFeature.filter((feature) =>
    selected.features
      .map((feature) => feature?.featureName)
      .includes(feature?.feature?.feature_name)
  );

  let priceOne = 0,
    priceTwo = 0,
    priceThree = 0;
  let dayOne = 0,
    dayTwo = 0,
    dayThree = 0;

  filteredPricingList?.forEach((list) => {
    priceOne += Number(list?.feature?.feature_price1);
    priceTwo += Number(list?.feature?.feature_price2);
    priceThree += Number(list?.feature?.feature_price3);

    dayOne += Number(list?.feature?.feature_time1);
    dayTwo += Number(list?.feature?.feature_time2);
    dayThree += Number(list?.feature?.feature_time3);
  });

  const handlePrice = (price, days) => {
    if (selected?.productDetails?.price == price) {
      setSelected({ ...selected, productDetails: { price: "", days: "" } });
    } else {
      setSelected({ ...selected, productDetails: { price, days } });
    }
  };

  return (
    <StartedAskingSection>
      <h2>Feature Pricing list</h2>
      <GridThree gap="4rem">
        <StepCard onClick={() => handlePrice(priceOne, 20)}>
          <PricingList>
            {selected?.productDetails?.price === priceOne ? (
              <div className="selected">
                <FaCheckCircle />
              </div>
            ) : (
              ""
            )}
            <FaArrowAltCircleRight />
            <p>{priceOne} for 20-30 days</p>
            <img src={leaf} className="leaf" />
          </PricingList>
        </StepCard>
        <StepCard onClick={() => handlePrice(priceTwo, 15)}>
          <PricingList>
            {selected.productDetails.price === priceTwo ? (
              <div className="selected">
                <FaCheckCircle />
              </div>
            ) : (
              ""
            )}
            <FaArrowAltCircleRight />
            <p>{priceTwo} for 15-20 days</p>
            <img src={leaf} className="leaf" />
          </PricingList>
        </StepCard>
        <StepCard
          onClick={() => handlePrice(priceThree, 5)}
          style={{
            border:
              selected.productDetails.price === priceThree
                ? "2px solid #000"
                : "",
          }}
        >
          <PricingList>
            {selected.productDetails.price === priceThree ? (
              <div className="selected">
                <FaCheckCircle />
              </div>
            ) : (
              ""
            )}
            <FaArrowAltCircleRight />
            <p>{priceThree} for 05-10 days</p>
            <img src={leaf} className="leaf" />
          </PricingList>
        </StepCard>
      </GridThree>
    </StartedAskingSection>
  );
}
