import React, { useEffect, useState } from 'react'
import { MissionVissionSection } from '../../../styles/About.styled'
import { Container } from '../../../styles/Common.styled'
// import missionimg from "../../../assets/images/mission.svg"
import missionimg from "../../../assets/images/mission1.png"
import visionimg from "../../../assets/images/vision.png"
import Aos from 'aos'

export default function MissionVission() {
    let [missionOrVission, setMissionOrVission] = useState("mission")

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [])
    return (
        <MissionVissionSection>
            <Container>
                <div className='flex no_direction' style={{marginBottom: "5rem"}}>
                    <p onClick={() => setMissionOrVission("mission")} style={{ fontWeight: missionOrVission === "mission" ? "700" : "500" }}>Our Mission</p>
                    <p onClick={() => setMissionOrVission("vission")} style={{ fontWeight: missionOrVission === "vission" ? "700" : "500" }}>Our Vision</p>
                </div>
                {
                    missionOrVission === "mission" ? <div className='flex'>
                        <div>
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum</p>
                        </div>
                        <div className='img_container'>
                            <img src={missionimg} alt="mission img" />
                        </div>
                    </div> : <div className='flex'>
                        <div className='img_container oder2'>
                            <img src={visionimg} alt="vision img" />
                        </div>
                        <div >
                            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum</p>
                        </div>
                    </div>
                }
            </Container>
        </MissionVissionSection>
    )
}
