import React, { useEffect, useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { ServiceFormContainer } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function AdminCashOutFlow() {
    let [error, setError] = useState("");
    let [selectedOrder, setSelectedOrder] = useState(null);
    const [cashOutFlow, setCashOutFlow] = useState({
        order_id: "",
        details: "",
        purpose: "",
        amount: "",
        date_of_payment: "",
        mode_of_payment: "",
        transaction_id: "",
        assigned_project: ""
    })
    const { updateDocument, response } = useFirestore("create_order");
    const { document } = useCollection("create_order");

    useEffect(() => {
        let filteredData = document?.filter(data => {
            return data?.order_id === cashOutFlow.order_id
        });
        setSelectedOrder(filteredData[0])
    }, [cashOutFlow?.order_id])

    const handlecashOutFlow = e => {
        e.preventDefault();
        let updatedObj = {
            ...selectedOrder,
            "cashOutFlow": [
                ...selectedOrder?.cashOutFlow,
                cashOutFlow
            ]
        }

        if (cashOutFlow?.order_id !== "" && cashOutFlow?.amount !== "" && cashOutFlow?.purpose !== "" && cashOutFlow?.date_of_payment !== "" && cashOutFlow?.mode_of_payment !== "" && cashOutFlow?.transaction_id !== "" && cashOutFlow?.assigned_project !== "") {
            updateDocument(selectedOrder?.id, updatedObj);
        } else {
            setError("Please fill all the required field!");
            return
        }

        setCashOutFlow({
            order_id: "",
            details: "",
            purpose: "",
            amount: "",
            date_of_payment: "",
            mode_of_payment: "",
            transaction_id: "",
            assigned_project: ""
        })
    }

    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <ServiceFormContainer style={{ margin: "2rem auto" }}>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>Create Cash out Flow</h2>

                    <form onSubmit={handlecashOutFlow}>
                        <label>
                            <p>Order Id:*</p>
                            <select onChange={e => setCashOutFlow({ ...cashOutFlow, order_id: e.target.value })}>
                                <option value="">Select an option</option>
                                {
                                    document?.map(project => (
                                        <option value={project?.order_id}>{project?.project_name}</option>
                                    ))
                                }
                            </select>
                        </label>
                        {/* <label>
                            <p>Order Id:*</p>
                            <input type="text" placeholder='Order Id' value={cashOutFlow.order_id} onChange={e => setCashOutFlow({ ...cashOutFlow, order_id: e.target.value })} />
                        </label> */}
                        <label>
                            <p>Details:*</p>
                            <input type="text" placeholder='Details' value={cashOutFlow.details} onChange={e => setCashOutFlow({ ...cashOutFlow, details: e.target.value })} />
                        </label>
                        <label>
                            <p>Purpose:*</p>
                            <select onChange={e => setCashOutFlow({ ...cashOutFlow, purpose: e.target.value })}>
                                <option value="">Select an option</option>
                                <option value="Salary">Salary</option>
                                <option value="Rent">Rent</option>
                                <option value="Lunch">Lunch</option>
                                <option value="Transportation">Transportation</option>
                                <option value="Vendor Bill">Vendor Bill</option>
                                <option value="Component Cost">Component Cost</option>
                                <option value="Other">Other</option>
                            </select>
                        </label>
                        <label>
                            <p>Date of payment:*</p>
                            <input type="date" placeholder='Date of payment' value={cashOutFlow.date_of_payment} onChange={e => setCashOutFlow({ ...cashOutFlow, date_of_payment: e.target.value })} />
                        </label>

                        <label>
                            <p>Mode of Payment:*</p>
                            <select onChange={e => setCashOutFlow({ ...cashOutFlow, mode_of_payment: e.target.value })}>
                                <option value="">Select an option</option>
                                <option value="Cash">Cash</option>
                                <option value="Cheque">Cheque</option>
                                <option value="Bank Transfer">Bank Transfer</option>
                                <option value="MFS">MFS</option>
                                <option value="Money Order">Money Order</option>
                            </select>
                        </label>

                        <label>
                            <p>Amount:*</p>
                            <input type="number" placeholder='Amount' value={cashOutFlow.amount} onChange={e => setCashOutFlow({ ...cashOutFlow, amount: e.target.value })} />
                        </label>

                        <label>
                            <p>Transaction Id:*</p>
                            <input type="text" placeholder='Transaction Id' value={cashOutFlow.transaction_id} onChange={e => setCashOutFlow({ ...cashOutFlow, transaction_id: e.target.value })} />
                        </label>

                        <label>
                            <p>Assigned Project:*</p>
                            <input type="text" placeholder='Assigned Project' value={cashOutFlow.assigned_project} onChange={e => setCashOutFlow({ ...cashOutFlow, assigned_project: e.target.value })} />
                        </label>
                        <input type="submit" value="Submit" />
                    </form>
                    {
                        error && <p style={{ textAlign: "center", color: "red", fontSize: "2rem" }}>{error}</p>
                    }
                    {/* <Button >Submit</Button> */}
                </ServiceFormContainer >
            </UserDashboard >
        </ProtectedRoute>
    )
}
