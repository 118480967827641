import React, { useState } from 'react'
import { FaExclamationCircle, FaFileMedicalAlt, FaLocationArrow, FaPhone, FaRegEnvelope, FaVoicemail } from 'react-icons/fa'
import { ContactFormSection } from '../../../styles/About.styled'
import { Container } from '../../../styles/Common.styled'
import { StartedForm } from '../../../styles/GetStarted.styled'
import { GridTwo } from '../../../styles/Grid.styled'

import facebook from "../../../assets/images/fb.png"
import linkedin from "../../../assets/images/linkedin1.png"
import email from "../../../assets/images/email.png"

import emailjs from '@emailjs/browser';

export default function ContactForm() {
  let [detail, setDetail] = useState({
    name: "",
    phone: "",
    email: "",
    companyName: "",
    idea: "",
  })

  let [success, setSuccess] = useState("");
  let [error, setError] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    const templateParams = {
      name: detail.name,
      user_email: detail.email,
      user_phone: detail.phone,
      companyName: detail.companyName,
      idea: detail.idea
    };

    if (detail.name !== "" && detail.phone !== "" && detail.email !== "" && detail.companyName !== "" && detail.idea !== "") {
      emailjs.send('service_mvl6und', 'template_22avzsm', templateParams, 'T6QISHKdsYsxMoeIM')
        .then((response) => {
          setSuccess("Successfully Submited");
          setDetail({
            name: "",
            phone: "",
            email: "",
            companyName: "",
            idea: "",
          })
        }, (err) => {
          setError(err);
        });
    } else {
      setError("Please, fill all the required field.")
    }
  }

  return (
    <ContactFormSection>
      <GridTwo>
        <div className='contact'>
          <div className='contact_detail'>
            <h3 style={{ fontSize: "3.2rem", margin: "2rem 0" }}>Visit Us</h3>
            <div className='details'>
              <FaLocationArrow />
              <div>
                <h6>Onghsak</h6>
                <p>3A, House 19, Road 27, J Block, Banani, 1213, Dhaka</p>
              </div>
            </div>

            <div style={{ margin: "5rem 0" }}>
              <h3 style={{ fontSize: "3.2rem", margin: "2rem 0" }}>Contact Us</h3>
              <div className='details'>
                <FaPhone />
                <div>
                  <p>+8801784440881</p>
                </div>
              </div>
              <div className='details'>
                <FaRegEnvelope />
                <div>
                  <p>hello@ongshak.com</p>
                </div>
              </div>
            </div>

            <div className='social'>
              <a href="https://www.facebook.com/ongshak/" target="_blank">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="https://www.linkedin.com/company/ongshak/mycompany/" target="_blank">
                <img src={linkedin} alt="linkedin" />
              </a>
              <a href="mailto:hello@ongshak.com" target="_blank">
                <img src={email} alt="Email" />
              </a>
            </div>

          </div>
        </div>
        <div className='contact_form'>
          <h2>Contact With Us</h2>
          <hr style={{ display: 'block', width: '130px', margin: "0 auto 4rem", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />
          <form onSubmit={handleSubmit}>
            <div className='flex'>
              <input type='text' value={detail.name} onChange={e => setDetail({ ...detail, name: e.target.value })} placeholder='Your name' />
              <input type='tel' value={detail.phone} onChange={e => setDetail({ ...detail, phone: e.target.value })} placeholder='Your phone number' />
            </div>
            <div className='flex'>
              <input type='email' value={detail.email} onChange={e => setDetail({ ...detail, email: e.target.value })} placeholder='Your email' />
              <input type='text' value={detail.companyName} onChange={e => setDetail({ ...detail, companyName: e.target.value })} placeholder='Your company name' />
            </div>
            <input type='text' value={detail.idea} onChange={e => setDetail({ ...detail, idea: e.target.value })} placeholder='Please send your message' />
            <div className='submit_container'>
              <input type="submit" value="Submit" className='submit' />
            </div>
          </form>
          {
            success && <p style={{ textAlign: "center", color: "green", fontSize: "1.8rem" }}>{success}</p>
          }
          {
            error && <p style={{ textAlign: "center", color: "red", fontSize: "1.8rem" }}>{error}</p>
          }
        </div>
      </GridTwo>
    </ContactFormSection>
  )
}
