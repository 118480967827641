import React from 'react'
import animation from "../../../assets/images/animation_greet.gif"
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useCollection } from '../../../hooks/useCollection'
import { GridTwo } from "../../../styles/Grid.styled"
import { GreetContainer } from '../../../styles/UserAdmin.styled'

export default function UserGreet() {
    const { user } = useAuthContext();

    const { document } = useCollection("Selected Feature");
    const feature = document?.filter(indFeature => indFeature?.userDetails?.email === user?.email);

    return (
        <GreetContainer bg="#f1f1f1">
            <div>
                <h2>Hello! {user?.displayName}</h2>
                {
                    feature[0]?.features?.length < 1 ? <p>We will contact you soon. After contacting you we will setup your dashboard</p> : <p>Explore your dashbaord</p>
                }
            </div>
            <div className='img_container'>
                <img src={animation} alt="greeting animation" />
            </div>
        </GreetContainer>
    )
}
