import React from 'react'
import { Container, Heading, ImgContainer } from '../../../../styles/Common.styled'
import { GridFour, GridTwo } from '../../../../styles/Grid.styled'
import { ServiceCard, ServiceSection } from '../../../../styles/Home.styled'

import mobileImage from "../../../../assets/images/web_dovepment2.png"
import webDevelopmentImage from "../../../../assets/images/web_development1.png"
import iotDevelopmentImage from "../../../../assets/images/IoT_development1.jpg"
import crossDevelopmentImage from "../../../../assets/images/cross.png"
import service1 from "../../../../assets/images/service1.svg"
import linkedin1 from "../../../../assets/images/linkedin1.png"
import { FaBuffer, FaCheckCircle } from 'react-icons/fa'

export default function Service() {
    return (
        <ServiceSection>
            <Container>

                <h2 className='heading'>Services</h2>

                <hr style={{ display: 'block', width: '10%', margin: "0 auto 4rem", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />

                <GridTwo gap="20rem" >
                    <div className='content'>
                        <p className="title">Mobile Application <br /> Development</p>
                        <div className='border'></div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                                {/* <img className='img' src={linkedin1} alt="service image" /> */}
                            </div>
                            <p className='desc'>Push a button to scale your team, or build brand new, from a global talent pool</p>
                        </div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Use AI-powered deep vetting to find the right candidates</p>
                        </div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Enjoy automated controls for productivity, security and more
                            </p>
                        </div>
                    </div>
                    <ImgContainer className='img'>
                        <img className='service_img' src={mobileImage} alt="mobile service" />
                    </ImgContainer>
                </GridTwo>
                <GridTwo gap="6rem" >
                    <ImgContainer justify="flex-start bottom">
                        <img className='service_img' src={webDevelopmentImage} alt="webDevelopmentImage service" />
                    </ImgContainer>
                    <div className='content right top'>
                        <p className="title">Web Application <br /> Development</p>
                        <div className='border'></div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Push a button to scale your team, or build brand new, from a global talent pool</p>
                        </div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Use AI-powered deep vetting to find the right candidates</p>
                        </div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Enjoy automated controls for productivity, security and more
                            </p>
                        </div>
                    </div>
                </GridTwo>
                <GridTwo gap="6rem" >
                    <div className='content'>
                        <p className="title">IoT System Design and <br /> Development</p>
                        <div className='border'></div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Push a button to scale your team, or build brand new, from a global talent pool</p>
                        </div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Use AI-powered deep vetting to find the right candidates</p>
                        </div>
                        <div className='content_detail'>
                            <div className='img_container'>
                                <FaCheckCircle />
                            </div>
                            <p className='desc'>Enjoy automated controls for productivity, security and more
                            </p>
                        </div>
                    </div>
                    <ImgContainer className='img'>
                        <img className='service_img' src={iotDevelopmentImage} alt="IoT Development service" />
                    </ImgContainer>
                </GridTwo>
            </Container>
        </ServiceSection>
    )
}




// service description
// ==> (Mobile Application
// Development) Imagine the existence of a single mobile app on multiple platforms but using a single codebase.Sounds cool, right ? Take a break if you are roaming with all those fantastic ideas but have no means to integrate them.Simply share your thoughts with us and see your fantastic vision turn into reality.

// ==> (Mobile Application
// Development) Are you an entrepreneur with all those bewildering entrepreneurial ideas? If you are, let us help you skyrocket your market gain by building the most appealing platform for your customer. Drop your requirements and watch your business turn into a beauty with the most outstanding, your very own web platform.

// ==> (IoT System Design and
// Development) The world of automation is already showing us the miracles that we never anticipated before - be it smart factory equipment or smart home security systems. Let us help you integrate the technology for your company before it’s too late. Make a smart decision for your smart company. We are just a knock away.