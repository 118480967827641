import React from 'react'
import { Link } from 'react-router-dom'
import { StartedTopSection } from '../../../styles/GetStarted.styled'
import ongshakLogo from "../../../assets/images/logo.svg"

export default function StartedTop() {
    return (
        <StartedTopSection>
            <Link to="/" style={{ textDecoration: "none", color: "currentColor" }}>
                <div>
                    <img src={ongshakLogo} alt="ongshak" />
                    {/* <h2>ongshak</h2> */}
                </div>
            </Link>
        </StartedTopSection>
    )
}
