import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useFirestore } from "../../../hooks/useFirestore";
import Footer from "../../../Shared/Footer";
import Navbar from "../../../Shared/Navbar";
import {
  BlogImageWrapper,
  BlogInput,
  BlogInputFile,
  BlogInputWrapper,
  BlogSection,
} from "../../../styles/Blog.styled";
import { Button, Container } from "../../../styles/Common.styled";
import RichTextEditor from "./RichTextEditor";
import { projectStorage } from "../../../firebase.config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import BlogerProtectedRoute from "../../../ProtectedRoute/BlogProtectedRoute";
import { Flex } from "../../../styles/Grid.styled";

export default function AddBlog() {
  const [blogPosted, setBlogPosted] = useState(false);
  const [blogError, setBlogError] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const [altText, setAltText] = useState("");
  const [imgDescription, setImgDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [blogContent, setBlogContent] = useState("");
  const [featureImage, setFeatureImage] = useState("");
  const [blogImage, setBlogImage] = useState("");
  const [socialImage, setSociaImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [blogCategory, setBlogCategory] = useState([]);
  const [fileError, setFileError] = useState("");
  const [fileProgress, setFileProgress] = useState("");
  const [processing, setProcessing] = useState(false);
  const [aboutCaseStudy, setAboutCaseStudy] = useState({
    name: "",
    url: "",
  });

  console.log("blogImage", blogImage);
  console.log("socialImage", socialImage);
  console.log("featureImage", featureImage);

  const { addDocument, response } = useFirestore("Blogs");

  const handleAdd = (e) => {
    if (!blogCategory.includes(categoryName)) {
      setBlogCategory([...blogCategory, categoryName]);
      setCategoryName("");
    }
  };

  let date = new Date().toLocaleString();
  const handleSubmit = (e) => {
    setProcessing(true);
    setBlogError("");
    let blog = {
      title: blogTitle,
      content: blogContent,
      tags: blogCategory,
      altText,
      imgDescription,
      metaDescription,
      featureImage,
      socialImage,
      blogImage,
      aboutCaseStudy,
      reaction: [],
      reviews: [],
      createdAt: date,
    };
    if (blogTitle !== "" && blogContent !== "" && blogImage !== "") {
      addDocument(blog);

      setTimeout(() => {
        setBlogPosted(true);
        setBlogTitle("");
        setBlogContent("");
        setFeatureImage("");
        setSociaImage("");
        setBlogImage("");
        setBlogCategory([]);
        setAltText("");
        setImgDescription("");
        setMetaDescription("");
        setProcessing(false);
        setAboutCaseStudy({
          name: "",
          url: "",
        });
      }, 4000);
    } else {
      setBlogError("Please fill all the required field");
      setProcessing(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setBlogPosted(false);
    }, 3000);
  }, [blogPosted]);

  const types = ["image/png", "image/jpeg", "image/jpg"];

  const handleImage = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setFileError("Please select an image file (png , jpg Or JPeg) for Logo");
      return;
    }
    const storageRef = ref(projectStorage, `files/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setBlogImage(downloadURL);
        });
      }
    );
  };

  const handleFeatureImage = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setFileError("Please select an image file (png , jpg Or JPeg) for Logo");
      return;
    }
    const storageRef = ref(projectStorage, `feature/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFeatureImage(downloadURL);
        });
      }
    );
  };

  const handleSocialImage = (e) => {
    let selected = e.target.files[0];

    if (selected && !types.includes(selected.type)) {
      // setDetails({ ...details, image: "" })
      setFileError("Please select an image file (png , jpg Or JPeg) for Logo");
      return;
    }
    const storageRef = ref(projectStorage, `social/${selected.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setFileProgress(progress);
      },
      (error) => {
        setFileError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setSociaImage(downloadURL);
        });
      }
    );
  };

  return (
    <>
      <BlogerProtectedRoute>
        <Navbar />
        <BlogSection>
          <Container>
            <h2 className="heading">Add Blog</h2>
            <BlogInputWrapper>
              {/* <p>Blog Title</p> */}
              <BlogInput
                value={blogTitle}
                onChange={(e) => setBlogTitle(e.target.value)}
                type="text"
                placeholder="Blog Title"
              />
            </BlogInputWrapper>
            <Flex>
              <BlogInputWrapper style={{ width: "100%" }}>
                {/* <p>Alt text</p> */}
                <BlogInput
                  value={altText}
                  onChange={(e) => setAltText(e.target.value)}
                  type="text"
                  placeholder="Alt text"
                />
              </BlogInputWrapper>
              <BlogInputWrapper style={{ width: "100%" }}>
                <BlogInput
                  value={imgDescription}
                  onChange={(e) => setImgDescription(e.target.value)}
                  type="text"
                  placeholder="Image description"
                />
              </BlogInputWrapper>
            </Flex>

            <Flex>
              <BlogInputWrapper style={{ width: "100%" }}>
                <BlogInput
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  type="text"
                  placeholder="Meta description"
                />
              </BlogInputWrapper>
              <BlogInputWrapper
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <BlogInput
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    type="text"
                    placeholder="Blog Category"
                  />
                </div>
                <button onClick={handleAdd}>Add</button>
              </BlogInputWrapper>
            </Flex>

            {blogCategory?.length ? (
              <div style={{ margin: "1rem 0" }}>
                <h4 style={{ marginBottom: "1rem", fontSize: "1.6rem" }}>
                  Selected Category
                </h4>
                <div
                  style={{ display: "flex", flexWrap: "wrap", gap: "1.5rem" }}
                >
                  {blogCategory?.map((category, index) => (
                    <p
                      key={index}
                      style={{
                        backgroundColor: "rgba(9, 132, 227,.2)",
                        padding: "1rem",
                        borderRadius: ".4rem",
                        fontSize: "1.4rem",
                      }}
                    >
                      {category}
                    </p>
                  ))}
                </div>
              </div>
            ) : null}

            <Flex>
              <BlogInputWrapper style={{ width: "100%" }}>
                {/* <p>Alt text</p> */}
                <BlogInput
                  value={aboutCaseStudy?.name}
                  onChange={(e) =>
                    setAboutCaseStudy({
                      ...aboutCaseStudy,
                      name: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Company Name"
                />
              </BlogInputWrapper>
              <BlogInputWrapper style={{ width: "100%" }}>
                <BlogInput
                  value={aboutCaseStudy?.url}
                  onChange={(e) =>
                    setAboutCaseStudy({
                      ...aboutCaseStudy,
                      url: e.target.value,
                    })
                  }
                  type="text"
                  placeholder="Company Url"
                />
              </BlogInputWrapper>
            </Flex>

            {featureImage ? (
              <BlogImageWrapper>
                <img src={featureImage} alt="blog image" />
              </BlogImageWrapper>
            ) : null}
            <BlogInputWrapper>
              <p style={{ color: "#0933A6" }}>Feature Image</p>
              <BlogInputFile
                onChange={(e) => handleFeatureImage(e)}
                type="file"
              />
            </BlogInputWrapper>

            {blogImage ? (
              <BlogImageWrapper>
                <img src={blogImage} alt="blog image" />
              </BlogImageWrapper>
            ) : null}
            <BlogInputWrapper>
              <p style={{ color: "#0933A6" }}>Blog Image</p>
              <BlogInputFile
                onChange={(e) => handleImage(e)}
                type="file"
                placeholder="Blog Title"
              />
            </BlogInputWrapper>

            {socialImage ? (
              <BlogImageWrapper>
                <img src={socialImage} alt="blog image" />
              </BlogImageWrapper>
            ) : null}
            <BlogInputWrapper>
              <p style={{ color: "#0933A6" }}>Social Image</p>
              <BlogInputFile
                onChange={(e) => handleSocialImage(e)}
                type="file"
                placeholder="Blog Title"
              />
            </BlogInputWrapper>

            <RichTextEditor setBlogContent={setBlogContent} />
            <div style={{ marginTop: "2rem" }}>
              <button onClick={handleSubmit}>
                {!processing ? "Submit" : "Processing"}
              </button>
            </div>
            {blogPosted ? (
              <p
                style={{
                  color: "green",
                  fontSize: "1.8rem",
                  marginTop: "2rem",
                }}
              >
                Blog posted Successfully
              </p>
            ) : null}
            {blogError ? (
              <p
                style={{ color: "red", fontSize: "1.8rem", marginTop: "2rem" }}
              >
                {blogError}
              </p>
            ) : null}
          </Container>
        </BlogSection>
        <Footer />
      </BlogerProtectedRoute>
    </>
  );
}
