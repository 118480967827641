import React from 'react'
import { Link } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection';
import { Container } from '../../../styles/Common.styled'
import { SuperAdminTable } from '../../../styles/SuperAdmin.styled'

export default function AllCustomer() {
    const { document: customers } = useCollection("customers");

    let sorted = customers?.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
    });
    return (
        <Container>
            <h2 style={{textAlign: "center", fontSize: "3rem"}}>Our Customers</h2>
            <SuperAdminTable style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                <div className='row'>
                    <div style={{ fontWeight: "700" }}>Date</div>
                    <div style={{ fontWeight: "700" }}>Serial Number</div>
                    <div style={{ fontWeight: "700" }}>Project Name</div>
                    <div style={{ fontWeight: "700" }}>Customer Name</div>
                    <div style={{ fontWeight: "700" }}>Details</div>
                    {/* <div style={{ fontWeight: "700" }}>action</div> */}
                </div>
                {
                    sorted?.map(feature => (
                        <div className="row">
                            <p >{new Date(feature?.createdAt?.toDate()).toLocaleString()}</p>
                            <p  >#{feature?.serialNumber}</p>
                            <p >{feature?.projectName}</p>
                            <p >{feature?.userDetails?.name}</p>
                            <Link to={`/super-admin/customer/${feature?.id}`}>
                                <button>detail</button>
                            </Link>
                        </div>
                    ))
                }
            </SuperAdminTable>
        </Container >
    )
}
