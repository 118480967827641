import React from 'react'
import { ModalBackdrop, ModalSelected } from '../styles/Common.styled'

export default function FeatureModal({ children, setShowModal, width }) {
    return (
        <ModalBackdrop >
            <div className='overlay' onClick={() => setShowModal(false)}></div>
            <ModalSelected width={width}>
                {children}
            </ModalSelected>
        </ModalBackdrop>
    )
}
