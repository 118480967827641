import React from 'react'
import { StepCard } from '../../../styles/GetStarted.styled'
import { GridThree } from '../../../styles/Grid.styled'

export default function PaymentDetails({ customerObject }) {
    return (
        <div>
            <GridThree>
                <StepCard bg="rgba(253, 161, 9, .3)" align="left">
                    <p>Project price</p>
                    <p style={{ fontWeight: "700" }}>{customerObject[0]?.productDetails?.price || 0}</p>
                </StepCard>
                <StepCard bg="rgba(100, 148, 220, .3)" align="left">
                    <p>Total payment</p>
                    <p style={{ fontWeight: "700" }}>{customerObject[0]?.productDetails?.advancedPayment || 0}</p>
                </StepCard>
                <StepCard bg="rgba(81, 206, 218, .3)" align="left">
                    <p>Due</p>
                    <p style={{ fontWeight: "700" }}>{(customerObject[0]?.productDetails?.price - customerObject[0]?.productDetails?.advancedPayment) || 0}</p>
                </StepCard>
            </GridThree>
        </div>
    )
}
