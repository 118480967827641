import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useReducer, useState } from 'react'
import { db, timeStamp } from '../firebase.config';

const initialState = {
    document: null,
    error: null,
    isLoading: false,
    success: false
}

const fireStoreReducer = (state, { type, payload }) => {
    switch (type) {
        case "IS_LOADING":
            return { document: payload, error: null, isLoading: true, success: false };

        case "ADD_DOCUMENT":
            return { document: payload, error: null, isLoading: false, success: true };

        case "UPDATE_DOCUMENT":
            return { document: payload, error: null, isLoading: false, success: true };

        case "ERROR":
            return { document: null, error: payload, isLoading: false, success: false };

        default:
            return state
    }
}

export const useFirestore = (collectionName) => {
    const [isCencelled, setIsCencelled] = useState(false)
    const [response, dispatch] = useReducer(fireStoreReducer, initialState);

    const ref = collection(db, collectionName);

    // dispatch if not cancelled
    const dispatchIfNotCancelled = (action) => {
        if (!isCencelled) {
            dispatch(action)
        }
    }

    // adding document into Database collection
    const addDocument = async (doc) => {
        dispatch({type:"IS_LOADING"});

        try {
            const createdAt = timeStamp.fromDate(new Date());
            const addingDocument = await addDoc(ref, { ...doc, createdAt });
            dispatchIfNotCancelled({ type: "ADD_DOCUMENT", payload: addingDocument })
        }
        catch (error) {
            dispatch({ type: "ERROR", payload: error.message })
        }
    }

    // adding document into Database collection
    const updateDocument = async (id, obj) => {
        dispatch({type: "IS_LOADING"});

        try {
            const userDoc = doc(ref, id);
            let updated = await updateDoc(userDoc, obj);
            dispatchIfNotCancelled({ type: "UPDATE_DOCUMENT", payload: updated })
            return updated
        }
        catch (error) {
            dispatchIfNotCancelled({ type: "ERROR", payload: error.message })
            return null
        }
    }

    useEffect(() => {
        return () => setIsCencelled(true);
    }, [])

    return { addDocument, response, updateDocument }
}