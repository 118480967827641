import { useEffect, useState } from "react"
import { useAuthContext } from "./useAuthContext"
import { auth } from '../firebase.config'
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
    let [isCancelled, setIsCancelled] = useState(false)
    let [isPending, setIspending] = useState(false)
    let [error, setError] = useState("")
    const { dispatch } = useAuthContext();
    const navigate = useNavigate("");

    const Login = (email, password) => {
        setError(null)
        setIspending(true)

        signInWithEmailAndPassword(auth, email, password)
            .then(res => {
                dispatch({ type: "LOGIN", payload: res.user })
                if (!isCancelled) {
                    setIspending(false)
                    setError(null);
                }
                navigate("/user-admin")
            })
            .catch(err => {
                if (!isCancelled) {
                    setIspending(false)
                    setError("Your email or password was incorrect")
                }
            })
    }

    useEffect(() => {
        return () => setIsCancelled(true)
    }, [])

    return { error, Login, isPending }
}