import React, { useState } from 'react'
import { Navigate } from 'react-router-dom';
import { Form } from '../../styles/GetStarted.styled';
import { LoginSection } from '../../styles/Login.styled';

export default function AdminLogin() {
    var main_credentials = JSON.parse(localStorage.getItem('credentials'));
    let [loginCredentails, setLoginCredentials] = useState({
        username: "",
        password: ""
    })

    const [user, setUser] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isUser(loginCredentails)) {
            localStorage.setItem('loggedIn', 'true');
            setUser(true);
        }
    }

    const isUser = (data) => {
        if (main_credentials[0].username === data.username && main_credentials[0].password === data.password) return true;
        else return false;
    }
    return (
        <>
            {
                user ? <Navigate to='/super-admin' /> : <LoginSection>
                    <h2 style={{marginBottom: "3rem", fontSize: "3rem"}}>Admin login credential</h2>
                    <Form onSubmit={handleSubmit}>
                        <label>
                            <p>Admin Email</p>
                            <input type="text" onChange={e => setLoginCredentials({ ...loginCredentails, username: e.target.value })} />
                        </label>
                        <label>
                            <p>Admin password</p>
                            <input type="password" onChange={e => setLoginCredentials({ ...loginCredentails, password: e.target.value })} />
                        </label>
                        <input type="Submit" value="login" />
                    </Form>
                </LoginSection>
            }

        </>
    )
}
