import React from 'react'
import { InputContainer, StartedAskingSection } from '../../../styles/GetStarted.styled'

export default function StartedOthersBusiness({ setSelected, selected, setPage, page }) {
    const handleKeypress = (e) => {
        if (e.charCode === 13) {
            setPage(7);
        }
    }
    return (
        <StartedAskingSection>
            <h2 style={{ textAlign: "center" }}>Please provide the requirement.</h2>
            <InputContainer >
                <textarea value={selected?.business} type="text" onChange={(e) => setSelected({ ...selected, business: e.target.value })} onKeyPress={page === 5 && selected.businessOthers && selected.business !== "" && selected.userDetails.fetching ? "" : () => handleKeypress()} />
            </InputContainer>
        </StartedAskingSection>
    )
}
