import { createContext, useReducer, useEffect } from "react";
import { auth } from "../firebase.config";

export const AuthContext = createContext()

export const authReducer = (state, { type, payload }) => {
    switch (type) {
        case "LOGIN":
            return { ...state, user: payload }

        case "LOGOUT":
            return { ...state, user: null }

        case "RESET":
            return { ...state, user: payload }

        case "AUTH__IS__READY":
            return { ...state, user: payload, authIsReady: true }

        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {

    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })


    useEffect(() => {
        const unSub = auth.onAuthStateChanged((user) => {
            dispatch({ type: "AUTH__IS__READY", payload: user })
            unSub()
        })
    }, [])

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            {children}
        </AuthContext.Provider>
    )
}