import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useCollection } from '../../../../hooks/useCollection';
import { Container } from '../../../../styles/Common.styled';
import { Table } from '../../../../styles/SuperAdmin.styled';

export default function FinanceMonthlyCashIn() {
    const { document: create_order } = useCollection("create_order");

    let [allCashInFlow, setAllCashInFlow] = useState(create_order)
    let [monthlyCashInFlow, setMonthlyAllCashInFlow] = useState([])
    let [month, setMonth] = useState("")

    useEffect(() => {
        let sorted = create_order?.filter(order => order?.cashInFlow?.Advanced || order?.cashInFlow?.Second_payment || order?.cashInFlow?.Third_payment || order?.cashInFlow?.Investment).sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
        });

        setAllCashInFlow(sorted);
    }, [create_order])


    useEffect(() => {
        let arr = [];
        create_order?.forEach(order => {

            if (order?.cashInFlow?.Advanced?.date_of_payment?.slice(0, 7) == month) {
                arr.push(order?.cashInFlow?.Advanced)
            }

            if (order?.cashInFlow?.Second_payment?.date_of_payment?.slice(0, 7) == month) {
                arr.push(order?.cashInFlow?.Second_payment)
            }
            if (order?.cashInFlow?.Third_payment?.date_of_payment?.slice(0, 7) == month) {
                arr.push(order?.cashInFlow?.Third_payment)
            }
            if (order?.cashInFlow?.Investment?.date_of_payment?.slice(0, 7) == month) {
                arr.push(order?.cashInFlow?.Investment)
            }
        })

        setMonthlyAllCashInFlow(arr);
    }, [month])

    return (
        <Container>
            <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Cashin</h2>
            <label>
                <p>Filtered by month:*</p>
                <input type="month" value={month} onChange={e => setMonth(e.target.value)} />
            </label>
            <h2>Total Cash in Flow: {monthlyCashInFlow?.reduce((acc, cur) => acc += Number(cur.amount), 0)}</h2>
            <Table style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }} grid="repeat(12, 1fr)">
                <div className='row' style={{borderBottom: "1px solid #000"}}>
                    {/* <div style={{ fontWeight: "700" }}>Project Name</div> */}
                    <div style={{ fontWeight: "700" }}>A/P</div>
                    <div style={{ fontWeight: "700" }}>D of A</div>
                    <div style={{ fontWeight: "700" }}>I/N</div>
                    <div style={{ fontWeight: "700" }}>M of P</div>
                    <div style={{ fontWeight: "700" }}>S/P</div>
                    <div style={{ fontWeight: "700" }}>D of S P</div>
                    <div style={{ fontWeight: "700" }}>I/N</div>
                    <div style={{ fontWeight: "700" }}>M of S P</div>
                    <div style={{ fontWeight: "700" }}>T P</div>
                    <div style={{ fontWeight: "700" }}>D of T P</div>
                    <div style={{ fontWeight: "700" }}>I/N </div>
                    <div style={{ fontWeight: "700" }}>N of T P</div>
                </div>
                {/* {
                    !month && allCashInFlow?.map(feature => (
                        <div className="row">
                            <p >{feature?.cashInFlow?.Advanced?.amount}</p>
                            <p >{feature?.cashInFlow?.Advanced?.date_of_payment}</p>
                            <p >{feature?.cashInFlow?.Advanced?.invoice_number}</p>
                            <p > {feature?.cashInFlow?.Advanced?.mode_of_payment}</p>
                            <p >{feature?.cashInFlow?.Second_payment?.amount}</p>
                            <p >{feature?.cashInFlow?.Second_payment?.date_of_payment}</p>
                            <p >{feature?.cashInFlow?.Second_payment?.invoice_number}</p>
                            <p > {feature?.cashInFlow?.Second_payment?.mode_of_payment}</p>
                            <p >{feature?.cashInFlow?.Third_payment?.amount}</p>
                            <p >{feature?.cashInFlow?.Third_payment?.date_of_payment}</p>
                            <p >{feature?.cashInFlow?.Third_payment?.invoice_number}</p>
                            <p > {feature?.cashInFlow?.Third_payment?.mode_of_payment}</p>
                        </div>
                    ))
                } */}

                {
                    month && monthlyCashInFlow?.map(feature => (
                        <div className="row">
                            {/* <p ></p> */}
                            <p >{feature?.amount}</p>
                            <p >{feature?.date_of_payment}</p>
                            <p >{feature?.invoice_number}</p>
                            <p > {feature?.mode_of_payment}</p>
                            <p >{feature?.amount}</p>
                            <p >{feature?.date_of_payment}</p>
                            <p >{feature?.invoice_number}</p>
                            <p > {feature?.mode_of_payment}</p>
                            <p >{feature?.amount}</p>
                            <p >{feature?.date_of_payment}</p>
                            <p >{feature?.invoice_number}</p>
                            <p > {feature?.mode_of_payment}</p>
                        </div>
                    ))
                }
                
            </Table>
        </Container >
    )
}
