import React from 'react'
import { BannerSection } from '../styles/Common.styled'

export default function Banner({children, team}) {
  return (
    <BannerSection bg={team}>
        {children}
        <div className='overlay'></div>
    </BannerSection>
  )
}
