import styled from "styled-components";

export const FooterSection = styled.footer`
    background: linear-gradient(270deg, #4F82CD 4.03%, #0933A6 99.31%);
    /* border-radius: 100px 100px 0px 0px; */
    padding:  4rem 4rem 1rem;
    color: #fff;

    font-size: 2rem;
    z-index: 3000;
    

    /* .logo_container {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        img {
            width: 20rem;
            height: auto;
        }
        h2 {
            font-size: 3.2rem;
            margin-bottom: 0;
        }
    } */

    h2 {
        font-size: 4rem;
        font-weight: 700;
        margin-bottom: 3rem;
    }

    .desc {
        line-height: 1.6;
        letter-spacing: 1.6;
        font-size: 1.6rem;
        /* text-align: center; */
    }

    .column {
        border-right: 1px solid #fff;
        padding-right: 2rem;
    }

    .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .routes {
        text-align: center;
        p {
            margin-bottom: 2rem;
            /* text-decoration: underline; */
            font-weight: 600;
        }
    }

    .contact {
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-bottom: 2rem;


        img {
            width: 3rem;
            height: 3rem;
            object-fit: contain;
        }
        p {
            font-size: 1.8rem;
        }
    }

    .bottom {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .social {
        display: flex;
        gap: 2rem;

        img {
            width: 4rem;
            height: 4rem;

        }
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        text-align: center;
        .column {
            margin-bottom: 2rem;
            border-right: none;
            display: flex;
            flex-direction: column;
            
            justify-content: center;
            align-items: center;
        }

        .bottom {
            flex-direction: column;
            gap: 2rem;
        }

        .contact svg {
            display: none;
        }
    }
`