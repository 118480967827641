import React, { useEffect, useState } from "react";
import Navbar from "../../Shared/Navbar";
import Banner from "../../Shared/Banner";
import Footer from "../../Shared/Footer";
import FactoryAutomationForm from "./Components/FactoryAutomationForm";

export default function FactoryAutomation() {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);
  };
  return (
    <div>
      <Navbar height={height} />
      <Banner>
        <h2>Factory Automation</h2>
      </Banner>
      <FactoryAutomationForm />
      <Footer />
    </div>
  );
}
