import React, { useState } from 'react'
import { StepCard } from '../../../styles/GetStarted.styled'
import { Circle, Phase, PhaseContainer, ProjectPhaseSection } from '../../../styles/UserAdmin.styled'
import { Button } from '../../../styles/Common.styled'

export default function ProjectPhase({ customerObject, featureCompletedPercentage }) {
    const [phase, setPhase] = useState(1);

    let uiProgress = 0;

    if ((customerObject[0]?.isUiDone?.developed && customerObject[0]?.isUiDone?.review && customerObject[0]?.isUiDone?.approved)) {
        uiProgress = 100;
    } else if ((customerObject[0]?.isUiDone?.developed && customerObject[0]?.isUiDone?.review)) {
        uiProgress = 66
    } else if ((customerObject[0]?.isUiDone?.developed)) {
        uiProgress = 33
    }

    return (
        <ProjectPhaseSection>
            <h4 className='heading'>Project phase of <span style={{ color: "#FDA109", fontWeight: "700" }}>{customerObject[0]?.projectName}</span></h4>
            {/* <p className='greet'>We are segmentise your project with <span style={{ color: "#FDA109", fontWeight: "700" }}>4</span> phase</p> */}
            <PhaseContainer>
                <div>
                    <Phase onClick={() => setPhase(1)} offset={440 - (440 * (uiProgress / 100))}>
                        <div className='outer'>
                            <div className='inner'>
                                <div className='number'>
                                    {uiProgress}%
                                </div>
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                            <defs>
                                <linearGradient id="GradientColor">
                                    <stop offset="0%" stop-color="#6494DC" />
                                    <stop offset="100%" stop-color="#51CEDA" />
                                </linearGradient>
                            </defs>
                            <circle cx="80" cy="80" r="70" stroke-linecap="round" />
                        </svg>
                    </Phase>
                    <div style={{ textAlign: "center",fontSize: "2rem", marginTop: "1rem" }}>UI Design</div>
                </div>
                <div>
                    <Phase onClick={() => setPhase(2)} offset={!customerObject[0]?.isDevelopmentDone ? 440 - (440 * (featureCompletedPercentage / 100)) : 0}>
                        <div className='outer'>
                            <div className='inner'>
                                <div className='number'>
                                    {customerObject[0]?.isDevelopmentDone ? 100 : (featureCompletedPercentage || 0)}%
                                    {/* {featureCompletedPercentage || 0}% */}
                                </div>
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                            <defs>
                                <linearGradient id="GradientColor">
                                    <stop offset="0%" stop-color="#6494DC" />
                                    <stop offset="100%" stop-color="#51CEDA" />
                                </linearGradient>
                            </defs>
                            <circle cx="80" cy="80" r="70" stroke-linecap="round" />
                        </svg>
                    </Phase>
                    <div style={{ textAlign: "center", fontSize: "2rem", marginTop: "1rem" }}>Development</div>
                </div>
                <div>
                    <Phase onClick={() => setPhase(3)} offset={440 - (440 * ((customerObject[0]?.isTestingDone ? 100 : 0) / 100))}>
                        <div className='outer'>
                            <div className='inner'>
                                <div className='number'>
                                    {customerObject[0]?.isTestingDone ? 100 : 0}%
                                </div>
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                            <defs>
                                <linearGradient id="GradientColor">
                                    <stop offset="0%" stop-color="#6494DC" />
                                    <stop offset="100%" stop-color="#51CEDA" />
                                </linearGradient>
                            </defs>
                            <circle cx="80" cy="80" r="70" stroke-linecap="round" />
                        </svg>
                    </Phase>
                    <div style={{ textAlign: "center", fontSize: "2rem", marginTop: "1rem" }}>Testing</div>
                </div>
                <div>
                    <Phase onClick={() => setPhase(4)} offset={440 - (440 * ((customerObject[0]?.isDeliveryDone ? 100 : 0) / 100))}>
                        <div className='outer'>
                            <div className='inner'>
                                <div className='number'>
                                    {customerObject[0]?.isDeliveryDone ? 100 : 0}%
                                </div>
                            </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                            <defs>
                                <linearGradient id="GradientColor">
                                    <stop offset="0%" stop-color="#6494DC" />
                                    <stop offset="100%" stop-color="#51CEDA" />
                                </linearGradient>
                            </defs>
                            <circle cx="80" cy="80" r="70" stroke-linecap="round" />
                        </svg>
                    </Phase>
                    <div style={{ textAlign: "center",  fontSize: "2rem", marginTop: "1rem" }}>Deployment</div>
                </div>



                {/* <Button >ui design</Button>
                <Button >feature development</Button>
                <Button onClick={() => setPhase(3)}>completed</Button> */}
            </PhaseContainer>


            {
                phase === 1 && customerObject[0]?.isUiDone?.developed ? <StepCard bg="#ffe9c7">
                    {customerObject[0]?.isUiDone?.developed && !customerObject[0]?.isUiDone?.approved && !customerObject[0]?.isUiDone?.review ? <p>We have completed your UI design. Here is the <a style={{ textDecoration: "none", fontWeight: "700", color: "#000" }} href={customerObject[0]?.isUiDone?.uiLink} target="_blank">link</a> to review it.</p> : ""}
                    {!customerObject[0]?.isUiDone?.approved && customerObject[0]?.isUiDone?.review ? <p>You have reviewed the UI design. We are changing it accordingly. <a style={{ textDecoration: "none", fontWeight: "700", color: "#000" }} href={customerObject[0]?.isUiDone?.uiLink} target="_blank">link</a></p> : ""}
                    {customerObject[0]?.isUiDone?.approved ? <p>We have finalised the UI design with you. Here is the final design <a style={{ textDecoration: "none", fontWeight: "700", color: "#000" }} href={customerObject[0]?.isUiDone?.uiLink} target="_blank">link</a>.</p> : ""}


                </StepCard> : ""
            }
            {
                phase === 2 && customerObject[0]?.isDevelopmentDone ? <StepCard bg="#ffe9c7">
                    {
                        customerObject[0]?.isDevelopmentDone ? <p>We have developed all the features. Now we are testing it. Here is the <a style={{ textDecoration: "none", fontWeight: "700", color: "#000" }} href={customerObject[0]?.developmentLink} target="_blank">link</a> where you can review the development. Please provide your feedback by clicking the button below. <div>
                        <p>currently we are dveloping your project with our specialist.</p>
                        <p>feature development percentage <span style={{ color: "green", fontWeight: "700" }}>{featureCompletedPercentage ? featureCompletedPercentage : 0}%</span></p>
                        <div>

                            {
                                customerObject[0]?.features?.map(feature => (
                                    <>
                                        <p>{feature?.featureName}</p>{feature?.isDevelopmentDone ? <span style={{ color: "green" }}>done</span> : <span style={{ color: "red" }}>developing</span>}
                                    </>
                                ))
                            }
                        </div>
                    </div></p> : ""
                    }

                </StepCard> : ""
            }
            {
                phase === 3 && customerObject[0]?.isTestingDone ? <StepCard bg="#ffe9c7">
                    {
                        customerObject[0]?.isTestingDone ? <p>We have ly tested the developed system. Now it ready for deployment. Please review the tested site in the <a style={{ textDecoration: "none", fontWeight: "700", color: "#000" }} href={customerObject[0]?.developmentLink} target="_blank">link</a>.</p> : ""
                    }
                </StepCard> : ""
            }
            {
                phase === 4 && customerObject[0]?.isDeliveryDone ? <StepCard bg="#ffe9c7">
                    {
                        customerObject[0]?.isDeliveryDone ? <p>We have deployed the project in this <a style={{ textDecoration: "none", fontWeight: "700", color: "#000" }} href={customerObject[0]?.hostingLink} target="_blank">link</a>.</p> : ""
                    }
                </StepCard> : ""
            }



        </ProjectPhaseSection >
    )
}








// previous circular
{/* <div style={{ textAlign: "center" }}>
                    <Circle onClick={() => setPhase(1)} className="circle per" percentage={uiProgress} >
                        <div className='inner'>{uiProgress}%</div>
                    </Circle>
                    <h2 className='inner'>ui design</h2>
                </div>
                <div style={{ textAlign: "center" }}>
                    <Circle onClick={() => setPhase(2)} className='circle per' percentage={featureCompletedPercentage}>
                        <div className='inner'>{featureCompletedPercentage}%</div>
                    </Circle>
                    <h2 className='inner'>development</h2>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Circle onClick={() => setPhase(3)} className='circle per' percentage={customerObject[0]?.isTestingDone ? "100" : "0"}>
                        <div className='inner'>{customerObject[0]?.isTestingDone ? "100%" : "0%"}</div>
                    </Circle>
                    <h2 className='inner'>Testing</h2>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Circle onClick={() => setPhase(3)} className='circle per' percentage={customerObject[0]?.isDeliveryDone ? "100" : "0"}>
                        <div className='inner'>{customerObject[0]?.isDeliveryDone ? "100%" : "0%"}</div>
                    </Circle>
                    <h2 className='inner'>Deployment</h2>
                </div> */}