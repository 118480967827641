import { Navigate } from 'react-router-dom';
import React from 'react';

const BlogerProtectedRoute = ({children}) => {
    let loggedIn = localStorage.getItem('Bloger_loggedIn');
    
    return(
        loggedIn === 'true' ? children : <Navigate to='/bloger-login'></Navigate>
    );
}

export default BlogerProtectedRoute;