import styled from "styled-components";
import bg from "../assets/images/bannerbg.jpg"
export const OurProcessSection = styled.section`
    padding: 0 0 4rem 0;
    z-index: 1;
    .heading {
        text-align: center;
        font-size: 4rem;
        color: #FDA109;
        font-weight: 800;
        margin-bottom: 2rem;
    }
`

export const OurProcessCard = styled.div`
    
    /* padding: 6rem 0; */
    margin-top: 3rem;
    
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    color: #fff;
    
    .user_process {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-around;
        padding: 6rem 0;
        border-radius: .8rem;
        /* background-color: rgba(37, 35,49, .9); */
        background-color: #5270C1;
        /* background-image: url(${bg}); */
        box-shadow: 4px 4px 8px rgba(0,0,0,.4);
        
        background-position: left;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* background-color: rgba(37, 35,49, .8); */
            border-radius: .8rem;
        }

        .card{
            display: flex;
            gap: 2rem;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;

            .anim {

                >div {
                    display: inline;
                }
            }
        }

        p {
            font-size: 2rem;
            font-weight: 700;
            color: #fff;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
        text-align:center;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        .user_process {
            .pay {
                display: none;
            }
        }


    }
`