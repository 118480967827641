import React from "react";
import Banner from "../../Shared/Banner";
import Footer from "../../Shared/Footer";
import Navbar from "../../Shared/Navbar";
import AllBlogs from "./Components/AllBlogs";

export default function Blogs() {
  return (
    <div>
      <Navbar />
      <Banner>
        <h2>Case Studies</h2>
      </Banner>
      <AllBlogs />
      <Footer />
    </div>
  );
}
