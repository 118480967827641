import React, { useEffect, useState } from 'react'
import { useCollection } from '../../../hooks/useCollection'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import { Container } from '../../../styles/Common.styled';
import { Table } from '../../../styles/SuperAdmin.styled';
import { UserDashboard } from '../../../styles/UserAdmin.styled';
import AdminSidebar from './AdminSidebar';

export default function ProjectRevenue() {
    const { document } = useCollection("create_order");


    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>Project revenue</h2>
                    <Table style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd", overflowX: "scroll", borderBottom: "none" }} grid="repeat(28, minmax(200px,1fr))">
                        <div className='row' style={{borderBottom: "1px solid #000"}}>
                            <div style={{ fontWeight: "700" }}>Serial No</div>
                            <div style={{ fontWeight: "700" }}>Quotation Number</div>
                            <div style={{ fontWeight: "700" }}>Order Id</div>
                            <div style={{ fontWeight: "700" }}>Order Date</div>
                            <div style={{ fontWeight: "700" }}>Project Name</div>
                            <div style={{ fontWeight: "700" }}>Project Type</div>
                            <div style={{ fontWeight: "700" }}>Company Name</div>
                            <div style={{ fontWeight: "700" }}>Development Timeline</div>
                            <div style={{ fontWeight: "700" }}>Start Date</div>
                            <div style={{ fontWeight: "700" }}>End Date</div>

                            <div style={{ fontWeight: "700" }}>Advance Payment</div>
                            <div style={{ fontWeight: "700" }}>Date of Advance</div>
                            <div style={{ fontWeight: "700" }}>Invoice Number</div>
                            <div style={{ fontWeight: "700" }}>Mode of Payment</div>
                            <div style={{ fontWeight: "700" }}>Second Payment</div>
                            <div style={{ fontWeight: "700" }}>Date of Second Payment</div>
                            <div style={{ fontWeight: "700" }}>Invoice Number</div>
                            <div style={{ fontWeight: "700" }}>Mode of Second Payment</div>
                            <div style={{ fontWeight: "700" }}>Third Payment</div>
                            <div style={{ fontWeight: "700" }}>Date of Third Payment</div>
                            <div style={{ fontWeight: "700" }}>Invoice Number</div>
                            <div style={{ fontWeight: "700" }}>Mode of Third Payment</div>
                            <div style={{ fontWeight: "700" }}>Total Value of Project</div>
                            <div style={{ fontWeight: "700" }}>Total Collection</div>
                            <div style={{ fontWeight: "700" }}>Due</div>
                            <div style={{ fontWeight: "700" }}>Cost</div>
                            <div style={{ fontWeight: "700" }}>Profit</div>
                            {/* <div style={{ fontWeight: "700" }}>Realized Profit/Loss</div> */}
                        </div>
                        {

                            document?.map(feature => (
                                <div className="row">
                                    <p >{feature?.sl_no}</p>
                                    <p >{feature?.qoutation_no}</p>
                                    <p >{feature?.order_id}</p>
                                    <p >{feature?.order_date}</p>
                                    <p >{feature?.project_name}</p>
                                    <p >{feature?.project_type}</p>
                                    <p >{feature?.company_name}</p>
                                    <p >{feature?.development_timeline}</p>
                                    <p >{feature?.start_date}</p>
                                    <p >{feature?.end_date}</p>

                                    <p >{feature?.cashInFlow?.Advanced?.amount}</p>
                                    <p >{feature?.cashInFlow?.Advanced?.date_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Advanced?.invoice_number}</p>
                                    <p > {feature?.cashInFlow?.Advanced?.mode_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Second_payment?.amount}</p>
                                    <p >{feature?.cashInFlow?.Second_payment?.date_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Second_payment?.invoice_number}</p>
                                    <p > {feature?.cashInFlow?.Second_payment?.mode_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Third_payment?.amount}</p>
                                    <p >{feature?.cashInFlow?.Third_payment?.date_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Third_payment?.invoice_number}</p>
                                    <p > {feature?.cashInFlow?.Third_payment?.mode_of_payment}</p>
                                    <p > {feature?.total_project_value}</p>
                                    <p > {Number(feature?.cashInFlow?.Advanced?.amount) + (Number(feature?.cashInFlow?.Second_payment?.amount) || 0) + (Number(feature?.cashInFlow?.Third_payment?.amount) || 0)}</p>
                                    <p > {Number(feature?.total_project_value) - (Number(feature?.cashInFlow?.Advanced?.amount) + (Number(feature?.cashInFlow?.Second_payment?.amount) || 0) + (Number(feature?.cashInFlow?.Third_payment?.amount) || 0))}</p>
                                    <p > {feature?.cashOutFlow?.reduce((acc, cur) => acc += Number(cur?.amount), 0)}</p>
                                    <p > {(Number(feature?.cashInFlow?.Advanced?.amount || 0) + (Number(feature?.cashInFlow?.Second_payment?.amount) || 0) + (Number(feature?.cashInFlow?.Third_payment?.amount) || 0)) - (feature?.cashOutFlow?.reduce((acc, cur) => acc += Number(cur?.amount), 0) || 0)}</p>
                                </div>
                            ))
                        }
                    </Table >
                </Container>
            </UserDashboard>
        </ProtectedRoute>
    )
}
