import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Container } from '../../../styles/Common.styled'
import { SuperAdminTable, Table } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function SuperAdminAllCashInFlow() {
    const { document: create_order } = useCollection("create_order");

    let sorted = create_order?.filter(order => order?.cashInFlow?.Advanced || order?.cashInFlow?.Second_payment || order?.cashInFlow?.Third_payment || order?.cashInFlow?.Investment).sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
    });


    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Cashin Flow</h2>
                    <Table style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }} grid="repeat(13, 1fr)">
                        <div className='row'>
                            <div style={{ fontWeight: "700" }}>P N</div>
                            <div style={{ fontWeight: "700" }}>A/P</div>
                            <div style={{ fontWeight: "700" }}>D of A</div>
                            <div style={{ fontWeight: "700" }}>I/N</div>
                            <div style={{ fontWeight: "700" }}>M of P</div>
                            <div style={{ fontWeight: "700" }}>S/P</div>
                            <div style={{ fontWeight: "700" }}>D of S/P</div>
                            <div style={{ fontWeight: "700" }}>I/N</div>
                            <div style={{ fontWeight: "700" }}>M of S P</div>
                            <div style={{ fontWeight: "700" }}>T P</div>
                            <div style={{ fontWeight: "700" }}>D of T P</div>
                            <div style={{ fontWeight: "700" }}>I/N</div>
                            <div style={{ fontWeight: "700" }}>M of T/P</div>
                        </div>
                        {
                            sorted?.map(feature => (
                                <div className="row">
                                    <p >{feature?.project_name}</p>
                                    <p >{feature?.cashInFlow?.Advanced?.amount}</p>
                                    <p >{feature?.cashInFlow?.Advanced?.date_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Advanced?.invoice_number}</p>
                                    <p > {feature?.cashInFlow?.Advanced?.mode_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Second_payment?.amount}</p>
                                    <p >{feature?.cashInFlow?.Second_payment?.date_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Second_payment?.invoice_number}</p>
                                    <p > {feature?.cashInFlow?.Second_payment?.mode_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Third_payment?.amount}</p>
                                    <p >{feature?.cashInFlow?.Third_payment?.date_of_payment}</p>
                                    <p >{feature?.cashInFlow?.Third_payment?.invoice_number}</p>
                                    <p > {feature?.cashInFlow?.Third_payment?.mode_of_payment}</p>
                                </div>
                            ))
                        }
                    </Table>
                </Container >
            </UserDashboard>
        </ProtectedRoute>
    )
}
