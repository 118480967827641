import React from 'react'
import { Sidebar } from '../../../styles/UserAdmin.styled'
import ongshakLogo from "../../../assets/images/logo.png"
import dashboardIcon from "../../../assets/images/Icons/dashboard.png";
import projectIcon from "../../../assets/images/Icons/project.png";
import logout from "../../../assets/images/logout.png";
import { Link, useNavigate } from 'react-router-dom';


export default function AdminSidebar() {
  const navigate = useNavigate("");

  const navigateHome = () => {
    navigate("/")
  }
  return (
    <Sidebar>
      <div onClick={navigateHome} className='logo_container'>
        <img src={ongshakLogo} alt="ongshak" />
      </div>
      <div className='sidebar_content'>
        <Link to="/super-admin/leads" style={{ color: "currentcolor", textDecoration: "none" }}>
          <div className='sidebar_content-data'>
            <img src={dashboardIcon} alt="dashbaord icon" />
            <p>Leads</p>
          </div>
        </Link>
        <Link to="/super-admin/customer" style={{ color: "currentcolor", textDecoration: "none" }}>
          <div className='sidebar_content-data'>
            <img src={dashboardIcon} alt="dashbaord icon" />
            <p>Customer</p>
          </div>
        </Link>
        <Link to="/super-admin" style={{ color: "currentcolor", textDecoration: "none" }}>
          <div className='sidebar_content-data'>
            <img src={projectIcon} alt="project icon" />
            <p>Add service</p>
          </div>
        </Link>
        <Link to="/super-admin/orders" style={{ color: "currentcolor", textDecoration: "none" }}>
          <div className='sidebar_content-data'>
            <img src={projectIcon} alt="project icon" />
            <p>Orders</p>
          </div>
        </Link>
        <Link to="/super-admin/all-blogs" style={{ color: "currentcolor", textDecoration: "none" }}>
          <div className='sidebar_content-data'>
            <img src={projectIcon} alt="project icon" />
            <p>All Blogs</p>
          </div>
        </Link>
        <Link to="/super-admin/finance-dashbard" style={{ color: "currentcolor", textDecoration: "none" }}>
          <div className='sidebar_content-data'>
            <img src={projectIcon} alt="project icon" />
            <p>Finance Dashboard</p>
          </div>
        </Link>
        <div onClick={
          () => {
            localStorage.setItem('loggedIn', 'false');
            navigate('/');
          }
        }
          className='sidebar_content-data'
        >
          <img src={logout} alt="project icon" />
          <p>Logout</p>
        </div>
        {/* <div className='sidebar_content-data'>
          <FaAlignJustify />
          <p>progress</p>
        </div> */}
      </div>
    </Sidebar>
  )
}
