import React from 'react'
import { Button, Container } from '../../../styles/Common.styled'
import { BuildDays, DetailsCard, FeatureContainer, NextButton, StepCard } from '../../../styles/GetStarted.styled'
import { GridTwo } from '../../../styles/Grid.styled'
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useState } from 'react';
import { useFirestore } from '../../../hooks/useFirestore';
import { useNavigate } from 'react-router-dom';
import { useCollection } from '../../../hooks/useCollection';
import emailjs from '@emailjs/browser';

import MailchimpSubscribe from "react-mailchimp-subscribe"
const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url} />

export default function PreviewPage({ selected }) {
    const navigate = useNavigate("")

    const { addDocument, response } = useFirestore("Selected Feature");
    const { document } = useCollection("serial");
    const { updateDocument, response: updateResponse } = useFirestore("serial")

    const handleSubmit = e => {
        let serialNumber = document[0]?.count;
        let updateSerialNumber = {
            ...document[0],
            count: document[0]?.count + 1
        };
        updateDocument(document[0]?.id, updateSerialNumber)
        addDocument({ ...selected, type: "lead", serialNumber });

        // if (response.error === null) {
        // }


        const templateParams = {
            user_name: selected.userDetails.name,
            user_email: selected.userDetails.email,
            project_name: selected.projectName,
            user_business: selected.business,
            user_project_purpose: selected.projectPurpose,
            user_project_price: selected.productDetails.price
        };

        // emailjs.send('service_6dr168w', 'template_0gal3yi', templateParams, 'user_VmFia1Ter6HW78JbH0WiK')
        emailjs.send('service_mvl6und', 'template_9m43cdd', templateParams, 'T6QISHKdsYsxMoeIM')
            .then((response) => {
                // console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                // console.log('FAILED...', err);
            });

        setTimeout(() => {
            navigate("/user-admin")
        }, 3000)
    }

    return (
        <div style={{ marginTop: "5rem" }}>
            <Container>
                <GridTwo gap="5rem">
                    <DetailsCard align="flex-start">
                        <div>
                            <p className="bold">Company name:</p>
                            <p>{selected.projectName}</p>
                        </div>
                        <div>
                            <p className="bold">Phone number:</p>
                            <p>{selected.userDetails.phoneNumber}</p>
                        </div>
                        <div>
                            <p className="bold">Email:</p>
                            <p>{selected.userDetails.email}</p>
                        </div>
                    </DetailsCard>
                    <DetailsCard align="flex-start">
                        <div>
                            <p className="bold">Project type:</p>
                            <div style={{ flexBasis: "50%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                {selected.projectTypes.map((project, index) => (
                                    <div>
                                        {/* {" "}{project} {index === selected.projectTypes.length - 1 ? "" : ","} */}
                                        {project}
                                    </div>
                                ))}
                            </div>
                            {/* <p>{selected.projectTypes.map(project => project,)}</p> */}
                        </div>
                        <div>
                            <p className="bold">Busines type:</p>
                            <p>{selected.business}</p>
                        </div>
                    </DetailsCard>
                </GridTwo>
                <h2 style={{ margin: '3rem 0 2rem 0' }}>Feature list</h2>
                <FeatureContainer>
                    {
                        selected.features.map(feature => (
                            <div>
                                <FaArrowAltCircleRight />
                                <p>{feature?.featureName}</p>
                            </div>
                        ))
                    }
                </FeatureContainer>
                <GridTwo>

                    <DetailsCard align="flex-start">
                        <div>
                            <p className="bold">Sub total:</p>
                            <p>{selected.productDetails.price} BDT</p>
                        </div>
                        <div>
                            <p className="bold">Total:</p>
                            <p>{selected.productDetails.price} BDT</p>
                        </div>
                    </DetailsCard>
                    {/* <DetailsCard>
                        <div>
                        <p>coupon</p>
                        <input type="text" onChange={handleCoupon} />
                        </div>
                        <div>
                        <p></p>
                            <Button onClick={handleCoupon}>apply</Button>
                        </div>
                    </DetailsCard> */}
                </GridTwo>
                <BuildDays>It will take {selected.productDetails.days == 20 ? "20 - 30" : selected.productDetails.days == 15 ? "15 - 20" : "05 - 10"} days to develop the project</BuildDays>
                <DetailsCard>
                    <h2 style={{ marginBottom: '1rem' }}>Term of condition</h2>
                    <p style={{ marginBottom: '1rem' }}>All VAT & AIT is excluded of Total Bill</p>
                    <p style={{ marginBottom: '1rem' }}>The Project Timeline will be effective after Four(4) working days of issuing the Work Order.</p>
                    <p>40% of the Total Payment is subject to be Paid as Advance</p>
                </DetailsCard>
                <div style={{ textAlign: "center" }}>
                    <NextButton hover="green" onClick={handleSubmit}>Confirm</NextButton>
                </div>
            </Container>

            {response.success ? <p style={{textAlign: "center"}}>Successfully confirmed your selected feature</p> : ""}
        </div>
    )
}

