import { Navigate } from 'react-router-dom';
import React from 'react';

const ProtectedRoute = ({children}) => {
    let loggedIn = localStorage.getItem('loggedIn');

    return(
        loggedIn === 'true' ? children : <Navigate to='/admin-login'></Navigate>
    );
}

export default ProtectedRoute;