import styled from "styled-components";
import contactBg from "../assets/images/contact.png"

export const TransformSection = styled.section`
    padding: 6rem 2rem;
    h2 {
        font-size: 3.6rem;
        text-align: center;
    }

    .card {
        text-align: center;
        img {
            width: 20rem;
            height: 20rem;
            margin-bottom: 1.5rem;
        }

        p {
            font-size: 2.4rem;
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
            
        h2 {
            font-size: 2.8rem;
        }
    }
`

export const MissionVissionSection = styled.section`
    padding: 6rem 2rem;
    /* background-color: rgba(9, 51, 166, .7); */
    background-color: #487eb0;
    min-height: 700px;

    .flex {
        justify-content: center;
        align-items: center;
        display: flex;
        gap: 4rem;
        color: #fff;
        margin-top: 2rem;

        & > div {
            flex: 1;
        }

        p {
            font-size: 2.2rem;
            cursor: pointer;
        }

        .img_container {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                max-width: 600px;
            }
        }

        .oder2 {
            order: 2;
        }

        @media (max-width: ${({ theme }) => theme.mobile}) {
            flex-direction: column;
            text-align: center;
            .no_direction {
                flex-direction: row;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        .no_direction {
            flex-direction: row;
        }
    }
`

export const EmployeeSection = styled.section`
    padding: 8rem 2rem;

    h2 {
        font-size: 3.4rem;
        margin-bottom: 4rem;
        text-align: center;
        @media (max-width: ${({ theme }) => theme.mobile}) {
            font-size: 2.8rem;
        }
    }
`

export const ContactFormSection = styled.section`
    /* padding: 8rem 2rem; */

    h2 {
        font-size: 3.4rem;
        margin-bottom: 2rem;
        text-align: center;
    }

    .contact {
        background-image: url(${contactBg});
        background-size: cover;
        background-position: center;
        background-color: #f1f1f1;
        width: 100%;
        height: 100%;

        padding: 50px 0px 50px 25%;

        &_detail {
        }

        .details {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            margin-bottom: 2rem;

            svg, img {
                width: 2rem;
                height: 2rem;
            }

            h6 {
                font-size: 1.8rem;
                margin-bottom: 1.5rem;
            }

            p {
                font-size: 1.8rem;
            }
        }
    }

    .social {
        display: flex;
        gap: 2rem;
        margin-top: 3rem;
        img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
        }
    }

    .contact_form {
        padding: 50px 25% 50px 15px;    
    }

    form {
        input, textarea {
            width: 100%;
            border: none;
            border-bottom: 1px solid #0933A6;
            color: #0933A6;
            padding: .5rem;
            margin: 1rem 0 2rem;
            font-size: 1.6rem;
            ::placeholder {
                color: rgba(9, 51, 166, .5);
            
            }
            :focus {
                outline: none;
            }
        }

        textarea {
            height: 300px;
            resize: none;
        }

        .submit_container {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            .submit {
                background-color: #0933A6;
                color: #fff;
                width: 150px;
                padding: .5rem 0;
                font-size: 2rem;
                font-weight: 700;
                border-radius: .8rem;
                cursor: pointer;
                transition: all .3s ease-in-out;

                :hover {
                    background-color: #1dd1a1;
                }
            }
        }

        .flex{
            display: flex;
            gap: 3rem;
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        h2 {
            font-size: 2.8rem;
        }
        
        .contact {
            padding: 4rem;
            text-align: left;
        }   
        
        .contact_form {
            padding: 4rem;

        }
    }
`