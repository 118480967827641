import React from 'react'
import { DynamicProgress, ProgressBar, ProgressSection } from '../../../styles/GetStarted.styled'
import { Circle } from '../../../styles/UserAdmin.styled'

export default function StartedProgress({ percentage, page }) {
  return (
    <ProgressSection>
      <ProgressBar>
        <DynamicProgress width={page === 9 ? "100" : percentage}></DynamicProgress>
      </ProgressBar>
      <div className='percentage'>
        {page === 9 ? "100" : Math.round(percentage)}%
      </div>
    </ProgressSection>
  )
}
