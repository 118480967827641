import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid';
import { useFirestore } from '../../../hooks/useFirestore';
import { Button } from '../../../styles/Common.styled';
import { ServiceFormContainer } from '../../../styles/SuperAdmin.styled';
import AdminServiceImge from './AdminServiceImge';

export default function AdminServiceForm() {
    const { addDocument, response } = useFirestore("Services")
    let [allFeature, setAllFeature] = useState([]);
    let [isFirstAdd, setIsFirstAdd] = useState(false);
    const serviceId = uuid()
    const featureId = uuid();


    let [service, setService] = useState({
        service_id: serviceId,
        feature_id: featureId,
        service_name: "",
    })

    let [feature, setFeature] = useState({
        service_id: serviceId,
        feature_id: featureId,
        feature_name: "",
        feature_description: "",
        feature_image: "",
        feature_price1: "",
        feature_time1: "",
        feature_price2: "",
        feature_time2: "",
        feature_price3: "",
        feature_time3: "",
    })

    const handleAdd = (e) => {
        e.preventDefault();
        setAllFeature([...allFeature, feature])
        setIsFirstAdd(true)

        setFeature({
            service_id: serviceId,
            feature_id: featureId,
            feature_name: "",
            feature_description: "",
            feature_image: "",
            feature_price1: "",
            feature_time1: "",
            feature_price2: "",
            feature_time2: "",
            feature_price3: "",
            feature_time3: "",
        })
    }

    const handleSubmit = () => {
        addDocument({ service, allFeature });
        setAllFeature([])
    }

    return (
        <ServiceFormContainer>

            <form onSubmit={handleAdd}>
                {
                    isFirstAdd == false ? <label>
                        <p>service name</p>
                        <input type="text" value={service.service_name} onChange={e => setService({ ...service, service_name: e.target.value })} placeholder='service name' />
                    </label> : ""
                }

                <label>
                    <p>feature name</p>
                    <input type="text" value={feature.feature_name} onChange={e => setFeature({ ...feature, feature_name: e.target.value })} placeholder='feature name' />
                </label>
                <label>
                    <p>feature description</p>
                    <input type="text" value={feature.feature_description} onChange={e => setFeature({ ...feature, feature_description: e.target.value })} placeholder='feature description' />
                </label>
                <AdminServiceImge feature={feature} setFeature={setFeature} />
                <label>
                    <p>price 1</p>
                    <input type="number" value={feature.feature_price1} onChange={e => setFeature({ ...feature, feature_price1: e.target.value })} placeholder='price 1' />
                </label>
                <label>
                    <p>time 1</p>
                    <input type="number" value={feature.feature_time1} onChange={e => setFeature({ ...feature, feature_time1: e.target.value })} placeholder='time 1' />
                </label>
                <label>
                    <p>price 2</p>
                    <input type="number" value={feature.feature_price2} onChange={e => setFeature({ ...feature, feature_price2: e.target.value })} placeholder='price 2' />
                </label>
                <label>
                    <p>time 2</p>
                    <input type="number" value={feature.feature_time2} onChange={e => setFeature({ ...feature, feature_time2: e.target.value })} placeholder='time 2' />
                </label>
                <label>
                    <p>price 3</p>
                    <input type="number" value={feature.feature_price3} onChange={e => setFeature({ ...feature, feature_price3: e.target.value })} placeholder='price 3' />
                </label>
                <label>
                    <p>time 3</p>
                    <input type="number" value={feature.feature_time3} onChange={e => setFeature({ ...feature, feature_time3: e.target.value })} placeholder='time 3' />
                </label>
                <input type="submit" value="add" />
            </form>
            <Button onClick={handleSubmit}>Submit</Button>
        </ServiceFormContainer >
    )
}
