import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute'
import { Container } from '../../../styles/Common.styled'
import { SuperAdminTable, Table } from '../../../styles/SuperAdmin.styled'
import { UserDashboard } from '../../../styles/UserAdmin.styled'
import AdminSidebar from './AdminSidebar'

export default function SuperAdminAllRandD() {
    const { document: create_order } = useCollection("create_order");

    let sorted = create_order?.filter(order => order?.rAndD).sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.createdAt?.toDate()) - new Date(a.createdAt?.toDate());
    });
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <Container>
                    <h2 style={{ textAlign: "center", fontSize: "3rem" }}>All Created Order</h2>
                    <Table grid="repeat(3, minmax(200px, 1fr))" style={{ margin: "2rem 0", padding: "4rem 0", fontSize: "2rem", backgroundColor: "#ddd" }}>
                        <div className='row'>
                            <div style={{ fontWeight: "700" }}>Project Name</div>
                            <div style={{ fontWeight: "700" }}>Cost</div>
                            <div style={{ fontWeight: "700" }}>Amount</div>
                            {/* <div style={{ fontWeight: "700" }}>Date of Payment</div>
                            <div style={{ fontWeight: "700" }}>Mode of Payment</div>
                            <div style={{ fontWeight: "700" }}>Asigned Project</div> */}
                            {/* <div style={{ fontWeight: "700" }}>action</div> */}
                        </div>
                        {
                            sorted?.map(order => (
                                <div className="row">
                                    <p >{order?.project_name}</p>
                                    <p >{order?.rAndD?.cost}</p>
                                    <p >{order?.rAndD?.amount}</p>
                                    {/* <p >{order?.date_of_payment}</p>
                                    <p >{order?.mode_of_payment}</p>
                                    <p >{order?.assigned_project}</p> */}
                                </div>
                            ))
                        }
                    </Table>
                </Container >
            </UserDashboard>
        </ProtectedRoute>
    )
}
