import styled from "styled-components";

export const NextButton = styled.button`
    font-size: 1.8rem;
    padding: 1.5rem 3rem;
    border: none;
    background-color: #0984e3;
    color: #fff;
    border-radius: .8rem;
    cursor: pointer;
    transition: all .3s;

    :hover {
        background:  ${({ hover }) => hover};
    }
`
export const PrevButton = styled.button`
    font-size: 1.8rem;
    padding: 1.5rem 3rem;
    border: none;
    background-color: #ff9f43;
    color: #fff;
    border-radius: .8rem;
    cursor: pointer;
`

export const GetStartedContainer = styled.section`
    min-height: 100vh;
    background-color: #fff;
    padding: 0 3rem;

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 4rem;
    }
`

export const StartedContainer = styled.div`
    max-width: 140rem;
    margin: 0 auto;
`

export const ProgressSection = styled.section`
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;

    /* position: relative; */

    .percentage {
        /* position: absolute;
        top: 15px;
        left: 50%;
        transform: translate(-15px, -0%); */

        /* background-color: rgba(81, 206, 218,.3); */
        font-size: 2rem;
        font-weight: 500;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #000;
        border-radius: 50%;
    }
`

export const ProgressBar = styled.div`
    width: 60%;
    /* margin: 0 auto; */
    /* background-color: rgba(255, 108, 10, .5); */
    background-color: ${({ bg }) => bg || "#ddd"};
    border-radius: .8rem;

    display: flex;
`

export const DynamicProgress = styled.div`
    padding: 5px;
    /* background-color: rgb(255, 108, 10); */
    background:  ${({ bg }) => bg || "#50CDDA"};
    border-radius: .8rem;
    width: ${({ width }) => `${width}%`};
`

export const StartedTopSection = styled.section`
    height: 10vh;
    min-height: 10vh;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.8rem;
    padding:  ${({ pad }) => pad || "1rem 0"};
    /* background-color: red; */

    div {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        img {
            width: 200px;
            height: auto;
        }
    }
`

export const StartedForm = styled.form`
    width: ${({ width }) => width || "30%"};
    margin: 0 auto;

    .flex {
        display: flex;
        gap: 1rem;

        img {
            width: 3rem;
            height: 2rem;
        }
    }

    label {
        margin: 1rem;

        p {
            font-size: 1.8rem;
            color: rgba(9, 51, 166, 1);
        }
    }
    input {
        width: 200px;
        border: none;
        border-bottom: 1px solid #1860CA;
        width: 100%;
        font-size: 1.8rem;
        padding: .5rem;
        color: #1860CA;
        margin-bottom: 1rem;

        ::placeholder {
            color: rgba(9, 51, 166, .5);
        
        }
        :focus {
            outline: none;
        }

    }

    .textarea {
        width: 100%;
        height: 200px;
        border: 1px solid #1860CA;
        resize: none;
        border-radius: .8rem;
        font-size: 1.8rem;
        padding: .5rem;
        color: #1860CA;
        margin-bottom: 1rem;

        :focus{
            outline: none;
        }
    }

    .submit {
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        padding: 1rem 2rem;
        background-color: #FDA109;
        border: none;
        cursor: pointer;
        border-radius: .8rem;
        margin-top: 1rem;
        color: #fff;
    }
    .submit_qoute {
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        padding: 1rem 2rem;
        background-color: #FDA109;
        border: none;
        cursor: pointer;
        border-radius: .8rem;
        margin-top: 1rem;
        color: #fff;
        width: 120px;
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        width: 90%;
    }
`

export const StartedButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StartedAskingSection = styled.div`
    min-height: calc(100vh - 40vh);
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */


    h2 {
        font-size: 3.2rem;
        margin-bottom: 3.2rem;
        font-weight: 500;
    }

    .infinite_scroll {

        ::-webkit-scrollbar{
            width: 0px;
            background-color: #F5F5F5;
        }
    }
`

export const StepCardWithHover = styled.div`
    .container {
        width: 220px;
        height: 220px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        }
        
        .box {
        position: absolute;
        width: 220px;
        height: 220px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        border-radius: 3px;
        overflow: hidden;
        -webkit-transition: -webkit-box-shadow ease 1s;
        transition: -webkit-box-shadow ease 1s;
        transition: box-shadow ease 1s;
        transition: box-shadow ease 1s, -webkit-box-shadow ease 1s;
        }
        
        .icon {
        position: relative;
        margin-top: 8px;
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
        -webkit-transition-delay: 0.32s;
                transition-delay: 0.32s;
        will-change: transform;
        }
        
        .icon_bg {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        border: 1px solid rgba(225, 227, 232, 0.18);
        -webkit-box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
                box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
        background-color: #fff;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
        -webkit-transition-delay: 0.32s;
                transition-delay: 0.32s;
        will-change: transform;
        }
        
        .title {
        position: relative;
        }
        
        .super-box:hover .box {
        /*   border: 1px solid rgba(225, 227, 232, 0.18); */
        -webkit-box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
                box-shadow: 0 3px 4px 0 rgba(44, 71, 146, 0.25);
        -webkit-transition-delay: 0.32s;
                transition-delay: 0.32s;
        }
        
        .super-box:hover .icon_bg {
        -webkit-transform: translateY(-32px) scale(6);
                transform: translateY(-32px) scale(6);
        }
        
        .super-box:hover .icon {
        -webkit-transform: translateY(-48px) scale(1.4);
                transform: translateY(-48px) scale(1.4);
        }
        
        .super-box {
            width: 220px;
            height: 220px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }
        
        .text {
        position: relative;
        top: -32px;
        width: 200px;
        text-align: center;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-transition-delay: 0.32s;
                transition-delay: 0.32s;
        }
        
        .super-box:hover .text {
        opacity: 0;
        -webkit-transform: translateY(-4px) scale(.8);
                transform: translateY(-4px) scale(.8);
        
        }
        
        .box::after {
        content: "sdfsdf";
        position: absolute;
        width: 170px;
        top: 111px;
        opacity: 0;
        font-size: 13px;
        color: #5d6494;
        line-height: 20px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        -webkit-transition-delay: 0.32s;
                transition-delay: 0.32s;
        }
        
        .super-box:hover .box::after {
        opacity: 1;
        }

        .dr {
        position: absolute;
        bottom: 16px; 
        right: 16px;
        width:100px;
        }
        
`

export const StepCard = styled.div`
    background-color: ${({ bg }) => bg || "#fff"};
    color: ${({ color }) => color || "#000"};
    padding: ${({ pad }) => pad || "3rem"};
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 9px 0px;

    display: flex;
    flex-direction: column;
    justify-content: ${({ justify }) => justify || "center"};
    align-items: ${({ align }) => align || "center"};
    border-radius: .8rem;
    min-height: ${({ height }) => height || ""};

    position: relative;
    overflow: hidden;
    transition: all .3s;

    :hover {
        transform: scale(1.05);
        box-shadow: rgb(0 0 0 / 20%) 3px 3px 9px 2px;
    }

    h2 {
        font-size: 2.8rem;
    }

    p {
        font-size: 2.2rem;
        z-index: 2;
        padding: 1rem 0;
    }

    img {
        width: 100px;
        height: 100px;
        margin-bottom: 2.5rem;
        z-index: 100;
    }

    .bg {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 70%;
        z-index: 1;
    }

    .leaf {
        position: absolute;
        bottom: 0;
        left: 20px;
        width: 5rem;
        height: 5rem;
        z-index: 0;
    }

    ul {
        li {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }
    }

    .selected {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;

        svg {
            fill: #1dd1a1;
            width: 3rem;
            height: 3rem;
        }
    }

    .tags {
        width: 100%;
        display: flex;
        gap: .5rem;
        /* grid-template-columns: repeat(3, 1fr); */
        z-index: 1;
        margin: 1rem 0;

        div {
            /* background-color: #ff9f43; */
            /* margin: .5rem; */
            color: #474747;
            /* padding: .5rem; */
            border-radius: 50px;
            text-align: left;
        }
    }

    .flex {
        margin-top: 1rem;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .btn_container {
        width: 100%;
        display: flex;
        gap: 2rem;
        justify-content: center;
        margin-top: 2rem;

        .detail {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ddd;
            padding: .7rem;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
            
            ::after {
                position: absolute;
                width: 60px;
                padding: 2px;
                bottom: -17px;
                left: -15px;
                text-align: center;
                background-color: #000;
                color: #fff;
                content: "See Details";
                opacity: 0;
            }
            

            :hover::after {
                opacity: 1;
                -webkit-transition: all 3s;
                transition: all .5s;
            }
            svg {
                width: 1rem;
                height: 1rem;
            }
        }

        .btn_select {
            width: 80%;
            max-width: 100px;
            border-radius: 50px;
            padding: .5rem 2rem;
        }
    }

    button {
        padding: 6px 10px;
        border: none;
        background-color: #000;
        color: #fff;
        border-radius: .8rem;
        cursor: pointer;
    }
`

export const PricingList = styled.div`
    display: flex;
    gap: 3rem;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 2rem; */

    svg {
        width: 3rem;
        height: 3rem;
        z-index: 1;
    }

    p {
        margin-bottom: 0;
    }
`

export const SelectedFeature = styled.div`
    width: ${({ width }) => width || "300px"};
    max-height: 600px;
    background-color: #fff;
    box-shadow: 2px 2px 6px 2px rgba(0,0,0,.3);
    padding: 3rem 2rem;
    border-radius: .8rem;
    position: relative;
    overflow-y: scroll;

    .cross {
        position: absolute;
        top: 5px;
        right: 10px;
        svg{
            width: 3rem;
            height: 3rem;
            cursor:pointer;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem;
        padding: .5rem;
        background-color: #ddd;
        p {
            font-size: 1.8rem;
        }

        svg {
            width: 3rem;
            height : 3rem;
            cursor: pointer;
        }
    }
`


export const InputContainer = styled.div`
    width: 50%;
    margin: 0 auto;
    input {
        width: 100%;
        margin: 0 auto;
        padding: 1rem;
        /* border-radius: .8rem; */
        font-size: 2rem;
        border: none;
        border-bottom: 2px solid #000;
        text-align: center;
        
        &:focus {
            outline: none;
        }
    }
    
    textarea {
        width: 100%;
        margin: 0 auto;
        resize: none;
        height: 300px;
        font-size: 2rem;
        padding: 1rem;
        border-radius: .8rem;
        &:focus {
            outline: none;
        }
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        width: 80%;
    }
`

export const InformationContainer = styled.div`
    background-color: #f1f1f1;
    padding: 4rem;
     label {
        p {
            font-size: 1.8rem;
            margin-bottom: 1rem;
        }
         input {
            width: 100%;
            padding: 1rem;
            border-radius: .4rem;
            margin-bottom: 1rem;
            font-size: 1.8rem;

        :focus {
            outline: none;
        }
    }
    }
`

export const SelectedContainer = styled.div`
background: #283048;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #859398, #283048);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #859398, #283048); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */




    color: #fff;
    padding: 4rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 5px rgba(0,0,0,.3);

    .list {
        font-size: 1.8rem;
        display: flex;
        /* justify-content: space-between; */
        /* font-size: 1.8rem; */
        > p {
            flex: 1;
            margin-bottom: 2rem;
        }

        ul {
            li {
                margin-bottom: 1rem;
                text-align: left;
            }
        }
    }

    
`

export const Form = styled.form`
    background: #7474BF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #348AC7, #7474BF);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #348AC7, #7474BF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: #fff;
    width: ${({ width }) => width || "50%"};
    margin: 0 auto;
    padding: 4rem;
    border-radius: .8rem;
    box-shadow: 2px 2px 5px rgba(0,0,0,.3);

    p {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    input, select {
        width: 100%;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: .4rem;
        border: 1px solid #ddd;

        :focus {
            outline: none;
        }
    }

    .submit {
        cursor: pointer;
        text-align: center;
        margin: 0 auto;
        background-color: #FDA109;
        color: #fff;
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        width: 90%;
    }
`

export const DetailsCard = styled.div`
    padding: 4rem;
    background-color: #fff;
    border: 1px solid #000;
    box-shadow: 2px 2px 10px 2px rgba(0,0,0,.3);
    border-radius: .4rem;
    font-size: 1.8rem;
    margin: 2rem 0;
    div {
        display: flex;
        align-items: ${({ align }) => align || "center"};
        margin-bottom: 1rem;
        >p {
            flex: 1;
        }

        input {
            flex: 1;
            width: 100%;
            padding: 1rem;
            border: .4rem;
            :focus {
                outline: none;
            }
        }

        .bold {
            font-weight: 700;
        }

        button {
            margin-top: 2rem;
        }

        .update_btn {
            padding: 1rem 2rem;
            border: none;
            background-color: rgba(9, 51, 166, .7);
            border-radius: .8rem;
            color: #fff;
            cursor: pointer;
            transition: all .3s;
            margin-top: 0;
            :hover {
                background-color: rgba(9, 51, 166, 1);
            }
        }

        @media (max-width: ${({ theme }) => theme.smallMobile}) {
            flex-direction: column;
        }
    }
`

export const FeatureContainer = styled.div`
    background-color: #ddd;
    padding: 4rem;
    border-radius: .4rem;
    display: Grid;
    gap:  2rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    div {
        display: flex;
        gap: 2rem;
        align-items: center;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }
    p {
        font-size: 1.6rem;
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
        text-align:center;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        gap: 1rem;
    }
`

export const BuildDays = styled.div`
    border: 2px solid #000;
    max-width: 700px;
    margin: 0 auto;
    padding: 2rem;
    font-size: 1.8rem;
    text-align: center;
`