import React, { useState, useEffect } from "react";
import Consultancy from "./Components/Consultancy/Consultancy";
import Partner from "./Components/Partner/Partner";
import Process from "./Components/Process/Process";
import Service from "./Components/Service/Service";
import "../../index.css";
import {
  Button,
  ButtonContainer,
  FloatingContainer,
} from "../../styles/Common.styled";
import Testimonial from "./Components/Testimonial/Testimonial";
import Portfolio from "./Components/Portfolio/Portfolio";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import Navbar from "../../Shared/Navbar";
import OurProcess from "./Components/OurProcess/OurProcess";
import Hero from "./Components/Hero/Hero";
import Footer from "../../Shared/Footer";
import start from "../../assets/images/start.png";
import Clients from "./Components/Clients/Clients";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import ReactGA from "react-ga";
import { useSignup } from "../../hooks/useSignup";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../../firebase.config";
import { doc, setDoc } from "firebase/firestore";
import AddBlog from "../BlogsDashboard/AddBlog/AddBlog";
import Calender from "./Components/Calender/Calender";

export default function Home() {
  const [isVisible, setIsVisible] = useState(true);
  const [height, setHeight] = useState(0);

  const { user } = useAuthContext("");

  const gaEventTracker = useAnalyticsEventTracker("floating-button");

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const listenToScroll = () => {
    let heightToHideFrom = 700;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    setHeight(winScroll);

    if (winScroll > heightToHideFrom && winScroll < 5600) {
      setIsVisible(true);
    } else if (winScroll > 5600) {
      setIsVisible(false);
    } else {
      setIsVisible(false);
    }
  };

  const GApageView = () => {
    ReactGA.pageview(window.location.path + window.location.search);
  };

  useEffect(() => {
    GApageView();
  }, []);

  console.log("user", user);

  return (
    <div>
      {isVisible && (
        <FloatingContainer>
          <Link to="/get-started">
            <ButtonContainer
              onClick={() => gaEventTracker("journey")}
              width="200px"
              bg="#FDA109"
              iconBg="#6494DC"
              color="#fff"
            >
              <div className="icons">
                <img src={start} alt="start" />
              </div>
              <p>Start Journey</p>
            </ButtonContainer>
            {/* <Button>start journey</Button> */}
          </Link>
        </FloatingContainer>
      )}

      <Hero height={height} />
      <OurProcess />
      <Service />
      <Process />
      {/* <Calender /> */}
      {/* <Partner /> */}
      <Clients />
      <Consultancy />
      <Testimonial />
      {/* <Portfolio /> */}
      <Footer />
    </div>
  );
}
