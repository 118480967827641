import React from 'react'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore'
import { StartedAskingSection, StepCard } from '../../../styles/GetStarted.styled'
import { GridThree } from '../../../styles/Grid.styled'
import leaf from "../../../assets/images/leaf.png"
import { FaCheckCircle } from 'react-icons/fa'

export default function StartedBusiness({ setSelected, selected, services }) {
    const handleBusiness = (value) => {
        if (!selected.businessOthers && value !== "businessOthers") {
            setSelected({ ...selected, business: selected.business === value ? "" : value });
        }
        if (selected.business === "" && value === "businessOthers") {
            setSelected({ ...selected, businessOthers: selected.businessOthers ? false : true })
        }
    }

    return (
        <StartedAskingSection>
            <h2>What type of business do you have?</h2>
            <GridThree gap="4rem">
                {
                    services?.map(service => (
                        <StepCard onClick={() => handleBusiness(service?.service?.service_name)}>
                            {
                                service?.service?.service_name === selected.business ? <div className='selected'>
                                    <FaCheckCircle />
                                </div> : ""
                            }
                            <p>{service?.service?.service_name}</p>
                            <img src={leaf} className="leaf" />
                        </StepCard>

                    ))
                }
                <StepCard onClick={() => handleBusiness("businessOthers")}>
                    {
                        selected.businessOthers ? <div className='selected'>
                            <FaCheckCircle />
                        </div> : ""
                    }
                    <p>Others</p>
                    <img src={leaf} className="leaf" />
                </StepCard>
                {/* <StepCard onClick={() => handleBusiness("ecom")}>
                    <h2>ecom</h2>
                </StepCard>
                <StepCard onClick={() => handleBusiness("portfolio")}>
                    <h2>portfolio</h2>
                </StepCard> */}
            </GridThree>
        </StartedAskingSection>
    )
}
