import React from 'react'
import Navbar from '../../../../Shared/Navbar'
import { ButtonContainer, Container } from '../../../../styles/Common.styled'
import { HeroSection, TopSection } from '../../../../styles/Home.styled'
import start from "../../../../assets/images/start.png"
import heroImg from "../../../../assets/images/heroImg.svg"
import bg from "../../../../assets/images/bg.png"
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom'
import useAnalyticsEventTracker from '../../../../hooks/useAnalyticsEventTracker'

export default function Hero({ height }) {
    const gaEventTracker = useAnalyticsEventTracker('hero-section');
    return (
        <>
            <Navbar height={height} />
            <TopSection>
                <HeroSection>
                    <img className='bg' src={bg} bg="bg" />
                    <div className='hero_content'>
                        <h2>Your Digital <br /> <span className='tech'>Tech</span> <span className='consult'>Consultant</span></h2>
                        <p style={{ lineHeight: "1.6" }}>We are here to solve your <span className='business'>Business Needs</span> <br /> through Softwares and Hardwares.</p>
                        <Link to="/get-started">

                            <ButtonContainer onClick={() => gaEventTracker('journey')} className='btn' width="290px" bg="#FDA109" iconBg="#6494DC" >
                                <div className='icons'>
                                    <img src={start} alt="start" />
                                </div>
                                <p style={{ fontSize: "1.6rem", color: "#fff" }}>Start Journey</p>
                            </ButtonContainer>
                        </Link>
                        <p className='qoute'>Have something in mind? <Link to="/qoute" style={{textDecoration: "none"}}><span>Get a Quote</span></Link></p>
                    </div>

                    <div className='right_img'>
                        <img src={heroImg} alt="heroImg" />
                    </div>
                </HeroSection>
                <p className='anim'><span style={{ color: "#6494DC" }}>Let’s </span><Typewriter
                    options={{
                        strings: ['Grow', 'Innovate', 'Build'],
                        autoStart: true,
                        loop: true,
                    }}
                /><span style={{ color: "#51CEDA" }}> Together</span></p>
            </TopSection>
        </>
    )
}
