import React from 'react'
import ProtectedRoute from '../../../../ProtectedRoute/ProtectedRoute'
import { UserDashboard } from '../../../../styles/UserAdmin.styled'
import AdminSidebar from '../AdminSidebar'
import FinanceAllOrder from './FinanceAllOrder'
import FinanceMonthlyCashIn from './FinanceMonthlyCashIn'

export default function FinanceDashboard() {
    return (
        <ProtectedRoute>
            <UserDashboard>
                <AdminSidebar />
                <div>
                    <FinanceAllOrder />
                    <FinanceMonthlyCashIn />
                </div>
            </UserDashboard>
        </ProtectedRoute>
    )
}
