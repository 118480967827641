import React from 'react'
import { StartedAskingSection, StepCard } from '../../../styles/GetStarted.styled'
import { GridTwo } from '../../../styles/Grid.styled'
import leaf from "../../../assets/images/leaf.png"
import { FaCheckCircle } from 'react-icons/fa'

export default function StartedForWho({ setSelected, selected }) {
    const data = [
        {
            id: "1",
            purpose: "Yourself"
        },
        {
            id: "2",
            purpose: "Company"
        },
    ]
    return (
        <StartedAskingSection>
            <h2>What is the purpose of this project?</h2>
            <GridTwo gap="4rem">
                {
                    data.map(purpose => (
                        <StepCard onClick={() => setSelected({ ...selected, projectPurpose: selected.projectPurpose === purpose.purpose ? "" : purpose.purpose })}>
                            {
                                selected.projectPurpose === purpose.purpose ? <div className='selected'>
                                    <FaCheckCircle />
                                </div> : ""
                            }
                            <p>for {purpose.purpose}</p>
                            <img src={leaf} className="leaf" />
                        </StepCard>
                    ))
                }
            </GridTwo>
        </StartedAskingSection>
    )
}
