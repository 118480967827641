import React from 'react'
import { Container, Heading } from '../../../../styles/Common.styled'
import { OurProcessCard, OurProcessSection } from '../../../../styles/OurProcess.styled'
import process1 from "../../../../assets/images/startprocess1.png"
import process2 from "../../../../assets/images/startprocess2.png"
import process3 from "../../../../assets/images/startprocess3.png"
import process4 from "../../../../assets/images/startprocess4.png"
import Typewriter from 'typewriter-effect';

export default function OurProcess() {
    return (
        <OurProcessSection>
            <Container>
                <h2 className='heading'>Process</h2>
                <hr style={{ display: 'block', width: '10%', margin: "0 auto", height: '3px', backgroundColor: 'rgba(253, 161, 9, 1)', border: 0 }} />
                <OurProcessCard>
                    <div className='user_process'>
                        <div className='overlay'>
                        </div>
                        <div className='card'>
                            <div>
                                <img src={process1} alt="process" />
                            </div>
                            <p>Select Business</p>
                        </div>
                        <div className='card'>
                            <div>
                                <img src={process2} alt="process" />
                            </div>
                            <p>Select Features</p>
                        </div>
                        <div className='card pay'>
                            <div>
                                <img src={process3} alt="process" />
                            </div>
                            <p>Pay  </p>
                        </div>
                    </div>
                    <div className='user_process'>
                        <div className='overlay'>
                        </div>
                        <div className='card'>
                            <div>
                                <img src={process4} alt="process" />
                            </div>
                            {/* <p>We will Design, Develop, Test and Deploy</p> */}
                            <p className='anim' id="services">We will <Typewriter
                                options={{
                                    strings: ['Design', 'Develop', 'Test', "Deploy"],
                                    autoStart: true,
                                    loop: true,
                                }}
                            /></p>
                        </div>
                    </div>
                </OurProcessCard>
            </Container>
        </OurProcessSection>
    )
}
