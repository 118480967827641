import React, { useState, useEffect } from 'react'
import { Card } from '../../../styles/Common.styled'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ProgressCard, ProgressSection } from '../../../styles/UserAdmin.styled';
import { Flex, GridTwo } from '../../../styles/Grid.styled';

export default function Progress({ customerObject, dayPassed, deliveryTime , featureCompletedPercentage}) {
    // const perdayProgress = 100 / deliveryTime;
    // let percentage = Math.round(perdayProgress * dayPassed);
    let totalProgress = +featureCompletedPercentage + ((customerObject?.isUiDone?.developed && customerObject?.isUiDone?.review && customerObject?.isUiDone?.approved) ? 10 : 0) + (customerObject?.isTestingDone ? 10 : 0) + (customerObject?.isDeliveryDone ? 5 : 0);

    return (
        <ProgressSection>
            <h2>Progress</h2>
            <ProgressCard>
                <div>
                    <p>Your Project Progress</p>
                    <p>{totalProgress || 0}%</p>
                </div>
                <div style={{ textAlign: "right" }}>
                    <CircularProgressbar value={totalProgress || 0} text={`${totalProgress || 0}%`} />
                </div>
            </ProgressCard>
        </ProgressSection>
    )
}
