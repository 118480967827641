import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react'
import { projectStorage } from '../../../firebase.config';

export default function AdminServiceImge({ feature, setFeature }) {
    const types = ['image/png', 'image/jpeg', "application/pdf"];

    const [thumbnailError, setThumbnailError] = useState("")

    const handleImage = e => {
        let selected = e.target.files[0]
        setThumbnailError(null)
        if (!selected) {
            setThumbnailError("Please, Select A file")
            return
        }

        if (!selected.type.includes("image")) {
            setThumbnailError("File should be Image")
            return
        }
        // if (selected.size > 300000) {
        //     setThumbnailError("Image file size must be less than 300kb'")
        //     return
        // }

        setThumbnailError(null)
        if (selected && !types.includes(selected.type)) {
            setFeature({ ...feature, feature_image: "" })
            setThumbnailError('Please select an image file (png , jpg Or JPeg) for Logo');
            return
        }

        const storageRef = ref(projectStorage, `files/${selected.name}`);
        const uploadTask = uploadBytesResumable(storageRef, selected);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                // setThumbnailError(progress);
            },
            (error) => {
                // setThumbnailError(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    setFeature({ ...feature, feature_image: downloadURL })
                });
            }
        );
    }
    return (
        <label>
            <p>feature Image</p>
            <input type="file" onChange={handleImage} placeholder='feature name' />
        </label>
    )
}
