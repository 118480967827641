import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../../firebase.config';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useFirestore } from '../../../hooks/useFirestore';
import { useLogin } from '../../../hooks/useLogin';
import { useSignup } from '../../../hooks/useSignup';
import { Button } from '../../../styles/Common.styled';
import { NextButton, PrevButton, StartedButtonContainer, StartedTopSection } from '../../../styles/GetStarted.styled'
import { Flex } from '../../../styles/Grid.styled';

export default function StartedBottom({ setPage, page, selected, phoneNumber, showSelectedFeature }) {
    const { signup, error, isPending, success } = useSignup();

    const navigate = useNavigate("")

    const { addDocument, response } = useFirestore("Selected Feature")

    const handleSubmit = e => {
        addDocument({ ...selected, type: "lead" })
        if (response.error === null) {
            navigate("/user-admin")
        }
    }


    useEffect(() => {
        handlePageNext();
    }, [isPending, success])


    const handlePageNext = () => {
        if ((page === 1 && selected.projectTypes.length > 0) || (page === 2 && selected.projectPurpose !== "") || (page === 3 && selected.projectName !== "") || (page === 4 && (selected.business !== "" || selected.businessOthers)) || (page === 5 && selected.features.length > 0) || (page === 6 && selected.productDetails.price !== "") || (page === 7 && phoneNumber !== "")) {
            setPage(prev => prev + 1);
        }


        if (page === 5 && selected.businessOthers && selected.business !== "" && selected.userDetails.fetching) {
            handleSubmit();
        }
        if (page === 5 && selected.businessOthers && selected.business !== "" && !selected.userDetails.fetching) {
            setPage(7);
        }

        if (page === 6 && selected.userDetails.name !== "" && selected.userDetails.email !== "" && selected.userDetails.password !== "" && selected.userDetails.phoneNumber !== "") {
            setPage(9);
        }

        if (page === 8 && (selected.userDetails.name !== "" && selected.userDetails.email !== "" && selected.userDetails.password !== "" && selected.userDetails.phoneNumber !== "")) {
            if (selected.userDetails.fetching) {
                if (page === 8 && selected.businessOthers && selected.business !== "") {
                    handleSubmit();
                } else {
                    setPage(prev => prev + 1);
                }

            } else {
                if ((page === 8 && selected.businessOthers && selected.business !== "")) {
                    signup(selected.userDetails.email, selected.userDetails.password, selected.userDetails.name, selected.userDetails.phoneNumber)
                    if (!isPending && success) {
                        handleSubmit();
                    }
                } else {

                    signup(selected.userDetails.email, selected.userDetails.password, selected.userDetails.name, selected.userDetails.phoneNumber)
                    if (!isPending && success) {
                        setPage(prev => prev + 1);
                    }
                }
            }
        }
    }

    const handlePagePrev = () => {
        if (page > 1) {
            setPage(prev => prev - 1);
        }

        if (page === 7 && selected.businessOthers) {
            setPage(5)
        }
    }
    return (
        <StartedTopSection noResponsive="true" pad="2rem 0">
            <StartedButtonContainer>
                {
                    page === 5 && selected.businessOthers ? "" : <PrevButton onClick={handlePagePrev}>Prev</PrevButton>
                }

                {
                    <NextButton onClick={handlePageNext}>Next</NextButton>
                }
            </StartedButtonContainer>

            {
                error ? <p>{error}</p> : ""
            }
        </StartedTopSection >
    )
}