import styled from "styled-components";

export const ServiceFormContainer = styled.section`
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    form {
        width: 800px;
        margin: 0 auto;

        label {
            font-size: 1.8rem;
        }
        
        input, select {
            width: 100%;
            padding: 1rem;
            margin: 1rem 0;
            font-size: 1.8rem;

            &:focus {
                outline: none;
            }
        }
    }

    button {
        display: block;
        text-align: center;
        width: 800px;
    }
`

export const SuperAdminContents = styled.div`
    background-color: #F4F5FB;
    padding: 4rem;
`


export const SuperAdminTable = styled.div`
    background-color: #f1f1f1;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 1.2rem;
    box-shadow: 2px 2px 5px rgba(0,0,0,.3);
    margin-top: 3rem;

    .row {
        display: grid;
        grid-template-columns: ${({ grid }) => grid || "repeat(5, 1fr)"};
        padding: 1rem;
        font-size: 1.8rem;
        border-bottom: 1px solid #ddd;
        text-align: center;

        > div:not(:last-child) {
            border-right: 1px solid #ddd;
            padding-left: 2rem;
        }

        svg {
            fill: #000;
        }
    }

    button {
        border: none;
        padding: 1rem;
        background-color: rgba(9, 51, 166, .7);
        border-radius: .8rem;
        color: #fff;
        margin: 0 2rem;
        cursor: pointer;
        
        :hover {
            background-color: rgba(9, 51, 166, 1);

        }
    }
`

export const Table = styled.div`
    max-width: 90%;
    /* overflow-x: scroll; */
    text-align: center;
    margin: 0 auto;
    border-radius: 1.2rem;
    box-shadow: 2px 2px 5px rgba(0,0,0,.3);
    margin-top: 3rem;
    background-color: #f1f1f1;
    
    .row {
        display: grid;
        grid-template-columns: ${({ grid }) => grid || "repeat(5, 1fr)"};
        padding: 1rem;
        font-size: 1.8rem;
        /* border-bottom: 1px solid #000; */
        text-align: center;

        > div:not(:last-child) {
            border-right: 1px solid #000;
            padding-left: 2rem;
        }

        svg {
            fill: #000;
        }
    }

    button {
        border: none;
        padding: 1rem;
        background-color: rgba(9, 51, 166, .7);
        border-radius: .8rem;
        color: #fff;
        margin: 0 2rem;
        cursor: pointer;
        
        :hover {
            background-color: rgba(9, 51, 166, 1);

        }
    }
`
