import React from "react";
import {
  StartedAskingSection,
  StepCard,
} from "../../../styles/GetStarted.styled";
import { GridThree, GridTwo } from "../../../styles/Grid.styled";
import webImg from "../../../assets/images/web.png";
import appImg from "../../../assets/images/app.png";
import iotImg from "../../../assets/images/iot.png";
import factory_automation from "../../../assets/images/factory_automation.png";
import factory from "../../../assets/images/factory.png";
// import leaf from "../../../assets/images/leaf.png"
import leaf from "../../../assets/images/bgCard.png";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const data = [
  {
    id: "1",
    name: "Web Appplication",
    img: webImg,
  },
  {
    id: "2",
    name: "Mobile Application",
    img: appImg,
  },
  {
    id: "3",
    name: "IoT System",
    img: iotImg,
  },
];

export default function StartedBuild({ setSelected, selected, setPage }) {
  const navigate = useNavigate();

  const handleProject = (value) => {
    if (selected.projectTypes.includes(value)) {
      let filtered = selected.projectTypes.filter((select) => select !== value);
      setSelected({ ...selected, projectTypes: [...filtered] });
    } else {
      setSelected({
        ...selected,
        projectTypes: [...selected.projectTypes, value],
      });
    }
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      setPage((prev) => prev + 1);
    }
  };

  const handleAutomation = () => {
    navigate("/factory-automation");
  };

  return (
    <StartedAskingSection>
      <h2>What do you want to build?</h2>
      <GridTwo gap="4rem">
        {data.map((project) => (
          <StepCard
            key={project.id}
            onClick={() => handleProject(project.name)}
            onKeyPress={handleKeypress}
          >
            {selected.projectTypes.includes(project.name) ? (
              <div className="selected">
                <FaCheckCircle />
              </div>
            ) : (
              ""
            )}
            <img src={project.img} alt="ongshak web" />
            <p>{project.name}</p>
            {/* <img className='bg' src={leaf} alt="design" /> */}
          </StepCard>
        ))}

        <StepCard onClick={() => handleAutomation()}>
          <img src={factory_automation} alt="ongshak web" />
          <p>Factory Automation</p>
        </StepCard>
      </GridTwo>
    </StartedAskingSection>
  );
}
