import styled from "styled-components";
import { keyframes } from 'styled-components'
import svgImage from "../assets/images/back_spin.png"
import backImage from "../assets/images/bottom_shape.png"
import psycure from "../assets/images/psycure.PNG"

const animate = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const serviceAnimate = keyframes`
    0% { 
        transform: translate(0,0);
    }
    65% {
        transform: translate(0,20px);
    }
    100% { 
        transform: translate(0,0); 
    }
`;

export const TopSection = styled.section`
    min-height: 100vh;
    position: relative;
    margin-top: -100px;
    z-index: 10;
    background: #fff;
    .bg {
        position: absolute;
        top: 0;
        right: 0; 
        z-index: -100;
        width: 50%;
        height: 80%;
    }

    h2 {
        line-height: 1.3;
    }

    .anim {
        font-size: 2rem;
        position: absolute;
        bottom: 50px;
        right: 30px;

        >div {
            display: inline;
            font-weight: 900;
            color: #FDA109;
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        min-height: 100vh !important;
        
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        .bg {
            width: 90%;
            display: none;
        }

        .anim {
            width: 100%;
            text-align: center;
            bottom: 35px;
            right: 50%;
            transform: translateX(50%)
        }
    }
    
`

export const HeroSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: calc(100vh - 90px);

    max-width: 140rem;
    margin: 0 auto;
    padding: 0 3rem;

    h2 {
        font-size: 6.2rem;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 2rem;
    }

    p {
        color: #444;
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .qoute {
        font-size: 1.8rem;
        color: #444;
        margin-top: 3rem;
        transition: all .3s;
        span {
            color: #6494DC;
            font-weight: 700;
            cursor: pointer;
            :hover {
                color:#546de5;
            }
        }
    }


    .hero_content {
        margin-top: 50px;
    }
    .tech, .business {
        color: #FDA109;
    }
    
    .business {
        font-weight: 600;
    }
    .consult {
        color: #6494DC;
    }

    .right_img {
        margin-top: 50px;
        width: 600px;
        height: 600px;
        animation: ${serviceAnimate} 5s linear infinite;
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        flex-direction: column;
        padding: 6rem 2rem;

        .right_img {
            width: 500px;
            height: 500px;
        }
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        gap: 1rem;
        .hero_content {
            text-align: center;
            margin-top: 100px;
        }

        .btn {
            margin: 0 auto;
        }
        h2 {
            font-size: 4.2rem;
        }

        .right_img {
            margin-top: 0px;
            width: 350px;
            height: 350px;
        }
    }
    
    @media (max-width: 350px) {
        .right_img {
            width: 270px;
            height: 270px;
        }
    }
`


export const ServiceSection = styled.section`
    padding: 2rem 0 0 0;
    /* background-color: #F1F1F1; */
    /* background-image: linear-gradient(98deg,#fff 0%,#ddd 100%); */

    .heading {
        text-align: center;
        font-size: 4rem;
        color: #FDA109;
        font-weight: 800;
        margin-bottom: 2rem;
    }

    .content {
        /* max-width: 450px; */
        /* letter-spacing: 1px; */

        .title {
            /* letter-spacing: 1.3px !important; */
        }

        &_detail {
            display: flex;
            gap: 2rem;
            /* justify-content: center; */
            align-items: center;
            margin: 2rem 0;
            :hover {
                .img_container {

                    background-color: #EBF1FC;
                    transition: all .3s ease-in-out;
                }
            }

            .img_container {
                border-radius: .8rem;
                transition: all .3s ease-in-out;
                
                img, svg {
                    width: 5rem;
                    height: 5rem;
                    padding: .5rem;
                    fill: #2E6EDF;
                }
            }

            p {
                margin: 0;
            }
        }
    }
    
    .desc {
        text-align: justify;
        font-size: 2.2rem;
    }

    .right {
        margin-left: auto;
    }

    .title {
        font-size: 3rem;
        display: inline-block;
        padding-bottom: 1rem;
        font-weight: 600;
        margin: 0;
    }

    .border {
        width: 200px;
        padding: 3px;
        background-color: #485460;
        margin-bottom: 3rem;
        
    }

    p {
        font-size: 1.8rem;
        margin-bottom: 4rem;
        font-weight: lighter;
        line-height: 1.6;
    }
    .service_img {
        max-width: 500px;
        /* animation: ${serviceAnimate} 3s linear infinite; */
    }

     @media (max-width: ${({ theme }) => theme.mobile}) {
        padding: 6rem 0;

        .content {
            margin: 2rem auto;
        }

        .img {
            justify-content: center;
        }
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 4rem 0;

        .title {
            display: block;
            text-align: left;
        }

        .top {
            grid-row: 1/2;
        }

        .bottom {
            grid-row: 2/3;
        }
    }
`

export const ServiceCard = styled.div`
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0,0,0,.3);
    border-radius: 2rem;
    padding: 4rem 2rem;
    text-align: center;
    transition: all .3s;

    img {
        width: 10rem;
        height: 10rem;
    }

    h2 {
        font-size: 2.2rem;
        margin: 2rem 0;
    }

    p {
        font-size: 1.8rem;
        margin-bottom: 0;
        text-align: center;
        line-height: 1.6;
    }

    &:hover {
        background-color: #14e2a4;
        color: #fff;
    }

    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        :not(:last-child) {
            margin-bottom: 2.5rem;
        }
    }

`

export const ProcessSection = styled.section`
    padding: 30rem 0 14rem 0;
    /* border-radius: 50% 50% 0 0; */
    /* background-image: linear-gradient(360deg,#ddd 0%,#fff 100%); */
    background-color: rgba(9,51, 166, .7);
    color: #fff;
    position: relative;

    &::before {
        content: '';
        background-image: url(${backImage});
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 115% 100%;
        padding-top: 31%;
        transform: scaleY(-1);
    }


    /* @media (max-width: ${({ theme }) => theme.mobile}) {
        border-radius: 5% 25% 0 0;
    } */
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 14rem 0 6rem 0;

        .heading {
            margin: 0;
        }
    }
`

export const ProcessContainer = styled.div`
    background: ${props => `url(${props.img}) no-repeat top center`};
    background-size: contain;
    display: Grid;
    gap: ${({ gap }) => gap || "2rem"};
    grid-template-columns: 1fr 1.4fr 1fr;
    justify-content: center;
    align-content: center;
    align-items: center;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        gap: 1rem;
    }
`

export const ProcessCard = styled.div`
    padding: 2rem;
    /* margin: 1.5rem 0; */
    width: 100%;

    display: flex;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    text-align: ${({ align }) => align || "left"};
    transition: all .3s;
    
    
    h2 {
        font-size: 2.2rem;
        margin-bottom: 1.2rem;
    }
    p {
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    
    /* &:hover svg {
        background-color: #377DEE;
        border-radius: 50%;
        fill: #fff;
        stroke: #fff;
        
       
    }

    &:hover img {
        background-color: #377DEE;
        border-radius: 50%;
        color: #fff;

    } */
    
    &:not(:last-child) {
        margin-bottom: 1.2rem;
    }
    
    /* .greet {
        flex: 2;
        background-color: red;
    } */
    .indication {
        min-width: 7rem;
        min-height: 7rem;
        border: 2px solid #fff;
        border-radius: 50%;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        svg {
            width: 100%;
            height: 100%;
            padding: 2rem;
        }
        
        img {
            width: 6rem;
            height: 6rem;
            padding: 1rem;
            object-fit: cover;
        }
    }
`


export const ProcessSteps = styled.div`
    padding: 1rem;
`

export const ProcessImage = styled.div`
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`

export const PartnerSection = styled.section`
    padding: 14rem 0;
    background-color: #377DEE;

    .greet {
        h2 {
            font-size: 4rem;
            color: #fff;
            margin-bottom: 2rem;
        }

        p {
            font-size: 2rem;
            color: #fff;
            line-height: 1.6;
        }

        @media (max-width: ${({ theme }) => theme.smallMobile}) {
            margin-bottom: 3rem;
        } 
    }

    img {
        width: 150px;
        height: 100%;
        object-fit: cover;
        transition: all .3s;

        opacity: .3;

        &:hover {
            opacity: 1;
        }
    }

    .grid_three {
        display: Grid;
        gap: ${({ gap }) => gap || "2rem"};
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
        align-content: center;

        img {
            width: 80%;
            height: 100%;
            object-fit: contain;
        }


        @media (max-width: ${({ theme }) => theme.smallMobile}) {
            h2 {
                font-size: 3.2rem;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        padding: 8rem 0;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 6rem 0;
    }
`

export const ConsultancySection = styled.section`
    padding: 8rem 0;
    background: linear-gradient(270deg, #4F82CD 4.03%, #0933A6 99.31%);
    color: #fff;

    .card {
        padding : 2rem;
        border-radius: .8rem;
        /* box-shadow: 2px 2px 8px rgba(0,0,0,.1); */

        h2 {
            font-size: 7.8rem;
        }
        p {
            font-size: 2rem;

        }
    }

    .greet {
        h2 {
            font-size: 5.2rem;
            margin-bottom: 2rem;
        }

        p {
            font-size: 2rem;
            line-height: 1.6;
            letter-spacing: 1px;
        }
    }

    .grid_four {
        display: Grid;
        gap: ${({ gap }) => gap || "2rem"};
        grid-template-columns: repeat(3, 1fr);
        align-items: ${({ align }) => align};
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        padding: 6rem 0;

        .greet {
            h2 {
                font-size: 4.2rem;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 4rem 0;
        .greet {
            h2 {
                font-size: 3.2rem;
            }
        }

        .grid_four {
            grid-template-columns: minmax(70px, 1fr) minmax(70px, 1fr)  minmax(70px, 1fr);
        }
    }
`



export const TestimonialSection = styled.section`
    background-color: #fff;
    padding: 8rem 0;
    .heading {
        text-align: center;
        font-size: 4rem;
        color: #FDA109;
        font-weight: 800;
        margin-bottom: 2rem;
    }

    .slide {
        /* min-height: 500px;  */
        /* background-color: #f1f1f1; */
        border-radius: 1.2rem; 
        /* border: 2px solid #000; */
        /* box-shadow: 2px 2px 10px 1px rgba(0,0,0,.1); */
    }
    
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        padding: 4rem 0;
        margin: 0 1rem;
    }
    `

export const TestimonialCard = styled.div`
    position: relative;
    border-radius: 1.2rem;
    margin-top: 6rem;
    width: 100%;
    border: 2px solid #808e9b;
    min-height: 210px;
    box-shadow: 4px 4px 8px rgba(0,0,0,.2);

    .content {
        padding: 6rem 2rem;
        text-align: center;
        width: 80%;
        margin: 0 auto;

        h2 {
            font-size: 2.8rem;
            font-weight: 700;
            margin-bottom: .5rem;
        }
        
        p {
            font-size: 2.2rem;
            line-height: 1.6;
            letter-spacing: 1px;
        }

        .designation {
            color: #000;
            font-weight: 700;
            margin-bottom: 1rem;
            font-style: italic;
        }
    }

    img {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 50%;
        object-fit: cover;

        transform: translate(-50%, -50%);
        border-radius: 50%;
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        :not(:last-child) {
            margin-bottom: 2rem;
        }
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        :not(:last-child) {
            margin-bottom: 8rem;
        }
    }
`

export const PortfolioSection = styled.section`
    padding: 12rem 0;
`

export const PortfolioCard = styled.div`
    padding: 14rem 6rem;
    position: relative;
    border-radius: 2.5rem;
    overflow: hidden;
    
    h2 {
        font-size: 2.2rem;
        margin-bottom: 3rem;
        z-index: 1;
    }
    
    p {
        font-size: 1.8rem;
        margin-bottom: 3rem;
        z-index: 1;
    }
    
    a {
        font-size: 1.6rem;
        margin-bottom: 3rem;
        z-index: 1;
        text-decoration: none;
        color: rgb(55, 125, 238);
    }

    

    &:hover {
        transition: all .3s;
        background-image: url(${backImage});
        width: 100%;
        height: 100%;
        background: linear-gradient(313deg,rgb(55, 125, 238) 0%,green 100%);
        /* background-image: url(${psycure}); */
        z-index: 1;
        top: 0;
        left: 0;
        opacity: .8;

        h2, p, a{
            color: #fff;
        }
        
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url(${psycure});
            z-index: -1;
            top: 0;
            left: 0;
            opacity: .3;
        }
    }

`


export const ClientSection = styled.section`
    padding: 8rem 0;

    .heading {
        text-align: center;
        font-size: 4rem;
        color: #FDA109;
        font-weight: 800;
        margin-bottom: 2rem;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 2px 2px 5px 1px rgba(0,0,0,.1);
        border-radius: .8rem;
        height: 250px;

        .healthport {
            width: 300px;
            height: auto;
        }

        img {
            width: 250px;
            height: auto;
        }
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
        .center {
            height: 200px;
            img {
                max-width: 150px;
                height: auto;
            }
        }
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        .center {
            height: 150px;
            img {
                max-width: 120px;
            }
            /* .healthport {
                width: 250px;
                height: auto;
            } */
        }
    }
`

export const ClientGrid = styled.div`
    display: Grid;
    gap: ${({ gap }) => gap || "2rem"};
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-content: center;
    align-items: ${({ align }) => align};
    padding: 2rem;

    @media (max-width: ${({ theme }) => theme.mobile}) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${({ theme }) => theme.smallMobile}) {
        align-items: center;
        gap: 1.5rem;
    }
`